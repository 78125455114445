
import { faDesktop, faMobileAlt, faTabletAlt } from '@fortawesome/free-solid-svg-icons';

const trafficShares = [
    { id: 1, label: "Desktop", value: 60, color: "secondary", icon: faDesktop },
    { id: 2, label: "Mobile Web", value: 30, color: "primary", icon: faMobileAlt }
];

const totalOrders = [
    { id: 1, label: "Year", value: [1, 5, 2, 5, 4, 3, 5, 7, 10, 12, 5, 8], color: "primary" }
];

export {
    trafficShares,
    totalOrders
};