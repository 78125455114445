import React, { useState } from "react";
const Terms = () => {
	
	const headingStyle = {
	  marginTop: '0in',
	  marginRight: '0in',
	  marginBottom: '8.0pt',
	  marginLeft: '0in',
	  fontSize: '21px',
	  fontFamily: '"var(--bs-font-sans-serif)"',
	  color: 'black',
	  textAlign:'center'
	};

	const questionStyle = {
	  marginTop: '0in',
	  marginRight: '0in',
	  marginBottom: '8.0pt',
	  marginLeft: '0in',
	  fontSize: '19px',
	  fontFamily: '"var(--bs-font-sans-serif)"',
	  color: 'black',
	};

	const answerStyle = {
	  marginTop: '0in',
	  marginRight: '0in',
	  marginBottom: '8.0pt',
	  marginLeft: '0.2in',
	  fontSize: '16px',
	  lineHeight: '107%',
	  color: 'black',
	};
	
	const staticPages = {
	  padding: '100px',
	  paddingTop: '100px',
	};
	
    return (
        <>
            <div class="staticPages" style={staticPages}>
				<p style={headingStyle}>
				  <strong>Website Terms and Conditions of Use</strong>
				</p>
				<p style={questionStyle}>
				  <strong>1. ACCEPTANCE OF TERMS</strong>
				</p>
				<p style={answerStyle}>
				  By accessing or using the Savvey Savers Network's website, products, and services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you may not access the website or use our products and services.
				</p>
				
				<p style={questionStyle}>
				  <strong>2. USER ACCOUNT</strong>
				</p>
				<p style={answerStyle}>
				  <p style={answerStyle}>2.1. You must be 18 years or older to sign up for an account on our website; by using our website, you are confirming to us that you are at least 18 years old. </p>
				  <p style={answerStyle}> 2.2. You must create an account to access the full features of our website or use our services. You are responsible for maintaining the confidentiality of your account information and password. </p>
				  <p style={answerStyle}>2.3. You agree to supply accurate and up-to-date information when registering your account and to update it promptly if there are any changes.</p>
				</p>
				
				<p style={questionStyle}>
				  <strong>3. USE OF SERVICES</strong>
				</p>
				<p style={answerStyle}>
				  <p style={answerStyle}> 3.1. You may use our website and services only for personal and lawful purposes and in accordance with these terms.</p>
				  <p style={answerStyle}> 3.2. You agree not to engage in any activity that interferes with or disrupts our services, servers, or networks. </p>
				</p>
				
				<p style={questionStyle}>
				  <strong>4. INTELLECTUAL PROPERTY</strong>
				</p>
				<p style={answerStyle}>
				  <p style={answerStyle}> 4.1. All content on this website, including text, graphics, logos, images, and software, is the property of Savvey Savers Network Limited and is protected by intellectual property laws. </p>
				  <p style={answerStyle}> 4.2. You may not use, reproduce, distribute, or modify any content from this website without the written consent of Savvey Savers Network Limited. </p>
				</p>
				
				<p style={questionStyle}>
				  <strong>5. PRIVACY POLICY</strong>
				</p>
				<p style={answerStyle}>
				  5.1. Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using our services, you agree to our Privacy Policy.
				</p>
				
				<p style={questionStyle}>
				  <strong>6. LIMITATION OF LIABILITY</strong>
				</p>
				<p style={answerStyle}>
				  6.1. Savvey Savers Network Limited is not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of our website, products, or services.
				</p>
				
				<p style={questionStyle}>
				  <strong>7. TERMINATION</strong>
				</p>
				<p style={answerStyle}>
				  7.1. We reserve the right to terminate or suspend your account and access to our services without notice for any reason, including violation of these terms.
				</p>
				
				<p style={questionStyle}>
				  <strong>8. GOVERNING LAW</strong>
				</p>
				<p style={answerStyle}>
				   8.1. These terms and conditions are governed by and construed by English laws. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts ofEngland.
				</p>
				
				<p style={questionStyle}>
				  <strong>9. CHANGES TO TERMS</strong>
				</p>
				<p style={answerStyle}>
				  9.1. We reserve the right to update or modify these terms and conditions at any time. Changes will be effective immediately upon being published on the website. It is your responsibility to review these terms periodically.
				</p>
				
				<p style={questionStyle}>
				  <strong>10. COOKIES</strong>
				</p>
				<p style={answerStyle}>
				  10.1This website uses cookies to enhance your browsing experience. Cookies are small text files stored on your device that help us analyse how you use our site. We use this information to improve our website's functionality and personalize your experience. By continuing to use this website, you consent to the use of cookies as described in this policy. You can manage your cookie preferences through your browser settings. Please note that disabling cookies may impact certain features on our site.
				</p>
				
			</div>

        </>
    )
}
export default Terms;