import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, Dropdown, InputGroup, Nav, Tab, Container, Modal } from '@themesberg/react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import Documentation from "../components/Documentation";

import Preloader from "../components/Preloader";
import Loader from "../components/Loader";

import Profile3 from "../assets/img/team/profilesImage.png";
import axios from 'axios';
import swal from 'sweetalert';
import { Image } from '@themesberg/react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';


export default () => {
	const [data, setData] = useState([]);
	const [showDefault, setShowDefault] = useState(false);
	const [loading, setLoading] = useState(true);
    const handleClose = () => setShowDefault(false);
	const [month, setMonth] = useState([]);
	const [proposal_status, SetProposalStatus] = useState("0");
	const [IncorrectDetails, setIncorrectDetails] = useState("");
	const [currentStatus, setCurrentStatus] = useState([]);
	const [requestedCollectionRequest, setRequestedCollectionRequest] = useState([]);
	const [completedCollectionRequest, setCompletedCollectionRequest] = useState([]);
	
	
	const [message, setMessage] = useState("");
	
	const [showCommitmentDefault, setShowCommitmentDefault] = useState(false);
	const [commitmentData, setCommitmentData] = useState([]);
	const handleCloseCommitment = () => setShowCommitmentDefault(false);
	
	const [correctRequest, setCorrectionRequest] = useState(false);
	const handleCorrectionRequest = () => setCorrectionRequest(false);
	
	
	const [amount, setAmount] = useState([]);
	const [commitmentgoal, setCommitmentGoal] = useState([]);
	const [commitmentmonth, setCommitmentMonth] = useState([]);
	const [severs, setSaversList] = useState([]);
	
	
	const storedUser = localStorage.getItem('user');
	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;
	const user_id = userObject.user_id;
	const role_id = userObject.role_id;
	
	const { id } = useParams();
	
	useEffect(()=>{
	  const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/commitment_months';  
			
		axios.get(apiBaseURL)
		.then(response => {
			if(response.data.status==="success"){
			  setMonth(response.data.data);
			}
			//setLoading(false)
			
			// Get Commitment data
			const apiBaseUrlForCommitment = process.env.REACT_APP_API_BASE_URL+'/get_commitment_history'; 
			const formDatasss = new FormData();
			formDatasss.append('request_id', id);
				
			axios.post(apiBaseUrlForCommitment, formDatasss, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			}).then(({ data }) => {
				if(data.status==="success"){
					setData(data.data);
					SetProposalStatus(data.proposal_status);
					setCurrentStatus(data.current_status);
					setRequestedCollectionRequest(data.requested_collection_details);
					setCompletedCollectionRequest(data.completed_correction_details);
					//setLoading(false)
					
					const apiBaseURL11 = process.env.REACT_APP_API_BASE_URL+'/commitment_month'; 
					axios.get(apiBaseURL11)
					  .then((response1) => {
						setCommitmentGoal(response1.data.data.commitment_goals);
						setAmount(response1.data.data.commitment_amount);
						//setLoading(false)
						
						updateMonthAccordingToAmount(data.collection_request);
						
					  })
					  .catch((error) => {
						console.error('Error fetching data:', error);
					  });
					
					
				}
				//setLoading(false)
			}).catch(error => {
				//setLoading(false)
			});
			
		})
		.catch(error => {
		  console.error('Error:', error);
		});
	},[]);
	
	
	function getMonthName(monthId) {
  // Add logic to get the month name based on the monthId
  // For example, you can use a library like 'date-fns' or a switch statement
  // Here is a simple example:
	  const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	  ];

	  return months[monthId - 1] || '';
	}
	
	// accept and reject requested Month
	const handleStatus = (id, actionType) => {
		  const formData1 = new FormData();
		  formData1.append('requested_id', id);
		  formData1.append('status', actionType);
		 
		  
		  let title = "";
		  let textMsg = "";
		  if(actionType === "1"){
			formData1.append('reject_reason', "");
			  
			title = "Approve Request";
			textMsg = "Are you sure you want to approve this request?"; 
			
			Swal.fire({
				title: title,
				text: textMsg,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Confirm',
				cancelButtonText: 'Cancel',
				confirmButtonColor: '#262B40',
			  }).then((result) => {
				if (result.isConfirmed) {
				  const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/request_collection_month_status_update';        
				  axios.post(apiBaseURL, formData1, {
					headers: {
					Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
					}
				  })
				  .then(({ data }) => {
					if(data.status==="success"){
					  setShowDefault(false);
					  Swal.fire('Request has been approved successfully.', '', 'success').then (() => {
						  window.location.reload();
					  })
					}
					//setLoading(false);
				  }).catch(error => {
					//setLoading(false);
				  });
				}
			  });
			
		  } else {
			title = "Reject Request";  
			textMsg = "Are you sure you want to reject this request?";   
			const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/request_collection_month_status_update'; 
			
			Swal.fire({
				title: title,
				text: textMsg,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Confirm',
				cancelButtonText: 'Cancel',
				confirmButtonColor: '#262B40',
			  }).then((result) => {
				if (result.isConfirmed) {
					
					Swal.fire({
					  title: 'Reject',
					  input: 'text',
					  inputPlaceholder: 'Enter rejected reason',
					  showCancelButton: true,
					  confirmButtonText: 'Confirm',
					  showLoaderOnConfirm: true,
					  preConfirm: (reason) => {
						  formData1.append('reject_reason', reason);
						  
						  axios.post(apiBaseURL, formData1, {
							headers: {
							Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
							}
						  })
						  .then(({ data }) => {
							if(data.status==="success"){
							  setShowDefault(false);
							  
							  Swal.fire('Request has been rejected successfully.', '', 'success').then (() => {
								  window.location.reload();
							  })
							}
							//setLoading(false);
						  }).catch(error => {
							//setLoading(false);
						  });
					  },
					});
					
				}
			  });
			  
		  }
	};
	
	
	
	{/* Send proposal */}
	const [formData, setFormData] = useState({
		month: '',
		comment: '',
		request_id:id
	});

	const [errors, setErrors] = useState({
		month: '',
		comment: '',
	});
  
	const [invalid, setInvalid] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
		  ...formData,
		  [name]: value,
		});
	};
	
	const handleSubmit = (e) => {
		e.preventDefault();
		// Get the name of the button clicked
		const buttonName = e.nativeEvent.submitter.name;

		const formsbutton = formData['buttonName'] = buttonName;

		//console.log(formData);
		let valid = true;
		const newErrors = {
		  month: '',
		  comment: '',
		};
		
		if(formData.month === ""){
			newErrors.month = 'Please Select proposal Month';
			valid = false;
		}
		if(formData.comment === ""){
			newErrors.comment = 'Please Enter Comment';
			valid = false;
		}
		setErrors(newErrors);

		if (valid) {
			setLoading(true);

			const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/send_proposal'; 
			
			axios.post(apiBaseURL, formData, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			})
			.then(({ data }) => {
				if(data.status==="success"){
					setShowDefault(false);
				}
				setLoading(false);
				window.location.reload();
			}).catch(error => {
				setLoading(false);
			});
			
		}
	};
	{/* End Send proposal */}
	
	
	
	function getTimeDifference(timestamp) {
	  /*	
	  const currentTime = new Date();
	  const postTime = new Date(timestamp);
	  const timeDifference = Math.abs(currentTime - postTime);

	  // Calculate time difference in minutes, hours, days, or weeks
	  const minutes = Math.floor(timeDifference / (1000 * 60));
	  if (minutes < 60) {
		return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
	  } else {
		const hours = Math.floor(minutes / 60);
		if (hours < 24) {
		  return `${hours} hour${hours > 1 ? 's' : ''} ago`;
		} else {
		  const days = Math.floor(hours / 24);
		  if (days < 7) {
			return `${days} day${days > 1 ? 's' : ''} ago`;
		  } else {
			const weeks = Math.floor(days / 7);
			return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
		  }
		}
	  }
	  */
	  
		const inputDate = new Date(timestamp);

		// Options for formatting the date
		const options = {
		  year: 'numeric',
		  month: 'short',
		  day: 'numeric',
		  hour: '2-digit',
		  minute: '2-digit',
		  hour12: true
		};

		// Format the date to "12 Nov 2023 12:15 PM" format
		const formattedDate = inputDate.toLocaleDateString('en-US', options);
		
		return formattedDate;
	  
	}
	
	
	{/* Take Action on proposal request*/}
	const handleRequest = (request_id, status, proposal_id) => {
	  
		const formDatasss = new FormData();
		formDatasss.append('request_id', request_id);
		formDatasss.append('status', status);
		formDatasss.append('proposal_id', proposal_id);
	  
		const apiBaseURLRequest = process.env.REACT_APP_API_BASE_URL+'/proposal_request_status'; 
		
		
		let title = "";
		let textMsg = "";
		if(status === 1){
			  
			title = "Accept Proposal";
			textMsg = "Are you sure you want to accept the proposed month?"; 
			
			Swal.fire({
				title: title,
				text: textMsg,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Confirm',
				cancelButtonText: 'Cancel',
				confirmButtonColor: '#262B40',
			  }).then((result) => {
				if (result.isConfirmed) {
					formDatasss.append('reject_reason', "");
					
				  axios.post(apiBaseURLRequest, formDatasss, {
					headers: {
					Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
					}
				  })
				  .then(({ data }) => {
					if(data.status==="success"){
					  setShowDefault(false);
					  Swal.fire('Request has been accepted successfully.', '', 'success').then (() => {
						  window.location.reload();
					  })
					}
				  }).catch(error => {
				  });
				}
			  });
			
		} else {
			title = "Reject Proposal";  
			textMsg = "Are you sure you want to reject the proposed month?"; 

			Swal.fire({
				title: title,
				text: textMsg,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Confirm',
				cancelButtonText: 'Cancel',
				confirmButtonColor: '#262B40',
			  }).then((result) => {
				if (result.isConfirmed) {
					
					Swal.fire({
					  title: 'Reject',
					  input: 'text',
					  inputPlaceholder: 'Enter rejected reason',
					  showCancelButton: true,
					  confirmButtonText: 'Confirm',
					  showLoaderOnConfirm: true,
					  preConfirm: (reason) => {
						  
						  formDatasss.append('reject_reason', reason);
						  
						  axios.post(apiBaseURLRequest, formDatasss, {
							headers: {
							Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
							}
						  })
						  .then(({ data }) => {
							if(data.status==="success"){
							  setShowDefault(false);
							  
							  Swal.fire('Proposal has been rejected successfully.', '', 'success').then (() => {
								  window.location.reload();
							  })
							}
						  }).catch(error => {
						  });
					  },
					});
					
				}
			  });
			  
		}
	  
	};
	{/*End Take Action on proposal request*/}
	
	
	
	
	{/* View Request Detail*/}
	const viewDetails = (id) => {

		setLoading(true);
		setShowCommitmentDefault(true);
		const formData1 = new FormData();
		formData1.append('requested_id', id);
		
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/user_commitment_details';  	 
		axios.post(apiBaseURL, formData1, {
		  headers: {
			Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
		  }
		})
		.then(({ data }) => {
			if(data.status==="success"){
			 setCommitmentData(data.data);
			}
			setLoading(false);
		}).catch(error => {
			setLoading(false);
		});
	}
	{/* End View Request Detail*/}
	
	
	
	const viewConfirmationDetails = (id) => {

		setLoading(true);
		setShowCommitmentDefault(true);
		const formData1 = new FormData();
		formData1.append('requested_id', id);
		
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/user_commitment_details';  	 
		axios.post(apiBaseURL, formData1, {
		  headers: {
			Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
		  }
		})
		.then(({ data }) => {
			if(data.status==="success"){
			 setCommitmentData(data.data);
			}
			setLoading(false);
		}).catch(error => {
			setLoading(false);
		});
	}
	
	
	{/* Send Final Detail to client*/}
	const sendFinalDetails = () => {
		setLoading(true)
		const apiBaseUrlForCommitment = process.env.REACT_APP_API_BASE_URL+'/send_confirmation_details'; 
		const formDatasss = new FormData();
		formDatasss.append('request_id', id);
			
		axios.post(apiBaseUrlForCommitment, formDatasss, {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		}).then(({ data }) => {
			if(data.status==="success"){
				setLoading(false)
				Swal.fire('Final detail has been send successfully.', '', 'success').then (() => {
				  window.location.reload();
				})
			}
			setLoading(false)
		}).catch(error => {
			setLoading(false)
		});
		
	};

	const handleInputChangeIncorrectDetails = (e) => {
		const { name, value } = e.target;
		setIncorrectDetails(value);
	};
	{/*End  Send Final Detail to client*/}
	
	
	// approve final detail
	const approveFinalDetails = (e) => {
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/approve_final_details';  	 
		
		Swal.fire({
		title: "Accept Details",
		text: "Are you sure you want to accept the details as correct?",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonText: 'Confirm',
		cancelButtonText: 'Cancel',
		confirmButtonColor: '#262B40',
	  }).then((result) => {
		if (result.isConfirmed) {
			setLoading(true);
			const formDatasss = new FormData();
			formDatasss.append('request_id', id);
			
			 axios.post(apiBaseURL, formDatasss, {
				headers: {
				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
				}
			  })
			  .then(({ data }) => {
				if(data.status==="success"){
				  setShowDefault(false);
				  Swal.fire('Details have been successfully confirmed.', '', 'success').then (() => {
					  window.location.reload();
				  })
				}
				setLoading(false);
			  }).catch(error => {
				setLoading(false);
			  });
		}
	  });
	}
	
	
	const sendCorrectionDetails = () => {
		
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/send_correction_details';  
		
		Swal.fire({
			title: "Send details for Correction",
			text: "Are you sure you want to send these details for correction?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirm',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#262B40',
		  }).then((result) => {
			if (result.isConfirmed) {
				
			  const formDatasss = new FormData();
			  formDatasss.append('request_id', id);
			  formDatasss.append('correction_details', IncorrectDetails);
				
			  axios.post(apiBaseURL, formDatasss, {
				headers: {
				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
				}
			  })
			  .then(({ data }) => {
				if(data.status==="success"){
				  setShowDefault(false);
				  Swal.fire('Correction details have been successfully sent.', '', 'success').then (() => {
					  window.location.reload();
				  })
				}
				//setLoading(false);
			  }).catch(error => {
				//setLoading(false);
			  });
			  
			}
		  });
		
	};
	
	
	{/* Send Messages */}
	const updateMessage = (e) => {
		const { name, value } = e.target;
		setMessage(value);
	}
	
	const sendMessage = (id) => {
		setLoading(true);
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/send_message';  
		
		const formDatasss = new FormData();
		  formDatasss.append('request_id', id);
		  formDatasss.append('message', message);
			
		  axios.post(apiBaseURL, formDatasss, {
			headers: {
			Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
			}
		  })
		  .then(({ data }) => {
			if(data.status==="success"){
				setData(data.data);
				SetProposalStatus(data.proposal_status);
				setCurrentStatus(data.current_status);
			}
			setMessage("");
			setLoading(false);
		  }).catch(error => {
			setLoading(false);
		  });
	}
	{/* End Send Messages */}
	
	
	{/* Complete saving commitment */}
	const completeSavingCommitment = () => {
		
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/complete_saving_commitment';  
		
		Swal.fire({
			title: "Complete Commitment",
			text: "Are you sure, do you want to complete this Saving commitment request",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirm',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#262B40',
		  }).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
			  const formDatasss = new FormData();
			  formDatasss.append('request_id', id);
				
			  axios.post(apiBaseURL, formDatasss, {
				headers: {
				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
				}
			  })
			  .then(({ data }) => {
				if(data.status==="success"){
				  setLoading(false);
				  Swal.fire('Correction detail has been sent successfully.', '', 'success').then (() => {
					  window.location.reload();
				  })
				}
			  }).catch(error => {
			  });
			  
			}
		  });
	};
	{/* End Complete of saving commitment */}
	
	
	
	{/* Correction of saving commitment */}
	const [formDataCommitments, setFormDataCommitments] = useState({
		amount: '',
		saving_goal: '',
		collection_month: '',
		requested_id:id
	});

	const [errorsCommitments, setErrorsCommitments] = useState({
		amount: '',
		saving_goal: '',
		collection_month: '',
	});

	const handleInputChangeCommitments = (e) => {
		const { name, value } = e.target;
		setFormDataCommitments({
		  ...formDataCommitments,
		  [name]: value,
		});
		
		if(name === "amount"){
			const apiBaseUrlForMonthThroughAmount = process.env.REACT_APP_API_BASE_URL+'/get_request_amount_available_month'; 
			const formDatas = new FormData();
			formDatas.append('amount', value);
				
			axios.post(apiBaseUrlForMonthThroughAmount, formDatas, {
			})
			.then(({ data }) => {
				if(data.status==="success"){
					setCommitmentMonth(data.data)
					
					setFormData(prevData => ({
						...prevData,
						["collection_month"]: ''
					  }));
					
				}
			}).catch(error => {
			});
			
		}
	};
	
	const makeCorrection = () => {
		setCorrectionRequest(true);
	}
	
	const handleSubmitCorrection = (e) => {
		e.preventDefault();
		
		let valid = true;
		const newErrors = {
		  amount: '',
		  saving_goal: '',
		  collection_month: '',
		};

		if(formDataCommitments.amount === ""){
			newErrors.amount = 'Saving commitment amount field is required';
			valid = false;
		}
		if(formDataCommitments.saving_goal === ""){
			newErrors.saving_goal = 'Saving Goal field is required';
			valid = false;
		}
		
		if(formDataCommitments.collection_month === ""){
			newErrors.collection_month = 'Please Select Collection Month';
			valid = false;
		}
		setErrorsCommitments(newErrors);

		if (valid) {
			setLoading(true);
			const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/make_corrections'; 
			
			axios.post(apiBaseURL, formDataCommitments, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			})
			.then(({ data }) => {
				if(data.status==="success"){
					setShowDefault(false);
					setLoading(false);
				}
				setLoading(false);
				window.location.reload(); 
			}).catch(error => {
				setLoading(false);
			});
		}
	};
	
	function updateMonthAccordingToAmount(collection_request){
		
		let amountsssss = collection_request.amount;
		const apiBaseUrlForMonthThroughAmount = process.env.REACT_APP_API_BASE_URL+'/get_request_amount_available_month'; 
		const formDatas = new FormData();
		formDatas.append('amount', amountsssss);
			
		axios.post(apiBaseUrlForMonthThroughAmount, formDatas, {
		})
		.then(({ data }) => {
			if(data.status==="success"){
				setCommitmentMonth(data.all_month)
				
				setFormDataCommitments(prevData => ({
					...prevData,
					["saving_goal"]: collection_request.saving_goal
				}));

				setFormDataCommitments(prevData => ({
					...prevData,
					["amount"]: collection_request.amount
				}));
				
				setFormDataCommitments(prevData => ({
					...prevData,
					["collection_month"]: collection_request.requested_month
				}));
				setLoading(false)
			}
		}).catch(error => {
		});
	}
	
	{/* end Correction of saving commitment */}
	
  return (
    <>
	{loading && <Loader/>}
	<Container className="px-0" style={{ marginTop: 30 }}>
	
		<div className="d-flex justify-content-between flex-column flex-sm-row mt-4 mb-2 col-12">
			<a className="fw-bold text-dark hover:underline d-inline-flex align-items-center mb-2 mb-lg-0 card-link" href="#/messages">
			</a>
			{role_id === 1 && (currentStatus.type === "requested" || currentStatus.type === "proposal_rejected") && (
				<div>
					<small className="text-muted fw-normal">
						<Button variant="primary" className="m-1" size="sm" onClick={() => handleStatus(id, '1')}>
							Accept
						</Button>
					</small>
					<small className="text-muted fw-normal">
						<Button variant="primary" className="m-1" size="sm" onClick={() => handleStatus(id, '2')}>
							Reject
						</Button>
					</small>
					<small className="text-muted fw-normal">
						<Button variant="primary" className="m-1" size="sm" onClick={() => setShowDefault(true)}>
							Send Proposal
						</Button>
					</small>
				</div>
			)}
		</div>

		
		<div className="col-12" className="p-3">
		
			<div className="shadow bg-gray-800 black text-white p-4 ms-md-5 ms-lg-6 mb-4 card border-0">
				<h5>Request Details :</h5>
				<p className="fs-6 fw-bold">Submitted By : {requestedCollectionRequest.savers_name}</p>
				<p className="fs-6 fw-bold">Requested Month : {requestedCollectionRequest.month_name}</p>
				<p className="fs-6 fw-bold">Monthly Savings Amount : £{requestedCollectionRequest.amount}</p>
				<p className="fs-6 fw-bold">Savings Goal : {requestedCollectionRequest.saving_goal}</p>
				<p className="fs-6 fw-bold">
				  Request Status : {
					requestedCollectionRequest.status === "0" ? 
					  (proposal_status === "1" ? "Accepted" :
					   proposal_status === "2" ? "Rejected" : "Pending") :
					requestedCollectionRequest.status === "1" ? "Approved" :
					requestedCollectionRequest.status === "2" ? "Rejected" :
					"Unknown"
				  }
				</p>
				<p className="fs-6 fw-bold">Request Comment : {requestedCollectionRequest.reason}</p>
				{(requestedCollectionRequest.reject_reason) ? <p className="fs-6 fw-bold">Reject Reason : {requestedCollectionRequest.reject_reason}</p> : <></> } 
			</div>

		
			{data.map((item, index) => {
			  const timeDifference = getTimeDifference(item.created_at);
			  return item.from_user === user_id ? (
				<div key={item.id} className="shadow bg-gray-800 black text-white p-4 ms-md-5 ms-lg-6 mb-4 card border-0">
				  <p className="fs-6 fw-bold">
					<Image src={Profile3} className="user-avatar md-avatar chat-avatar rounded-circle" /> {item.from_user_name} <span className="time"> {timeDifference} </span>
					
				  </p>
				  <p className="fs-5 fw-light">
				   {item.type === "proposal_accepted" && role_id === 0 ? (
						  <span>
							You have accepted the proposed collection month of{' '}
							{getMonthName(item.month_id)}. Please navigate to your Savings Commitment page to see more details.
						  </span>
						) : (
						  <span>{item.comments}</span>
					)}
					
				    {item.is_proposal_request === "1" && currentStatus.type === "proposal_requested" && role_id === 0 && (currentStatus.id === item.id) && (
						<div style={{ float: "right", color: "red" }}>
							<button type="submit" className="d-inline-flex align-items-center text-white btn btn-primary white" onClick={() => handleRequest(item.collection_month_request_id, 1, item.proposal_id)}>Accept</button>&nbsp;
							<button type="submit" className="d-inline-flex align-items-center text-white btn btn-primary white" onClick={() => handleRequest(item.collection_month_request_id, 2, item.proposal_id)}>Reject</button>
						</div>	
					)}</p>
				</div>
			  ) : (
				<div key={item.id} className="shadow p-4 mb-4 card border-0">
				  <div className="text-left">
					<p className="fs-6 fw-bold">
					  <Image src={Profile3} className="user-avatar md-avatar chat-avatar rounded-circle" /> {item.from_user_name} <span className="time"> {timeDifference} </span>
					</p>
					<p className="fs-5 fw-light">
					{item.type === "proposal_accepted" && role_id === 0 ? (
						  <span>
							You have accepted the proposed collection month of{' '}
							{getMonthName(item.month_id)}. Please navigate to your Savings Commitment page to see more details.
						  </span>
						) : (
						  <span>{item.comments}</span>
					)}
					
					{item.is_proposal_request === "1" && currentStatus.type === "proposal_requested" && role_id === 0 && (currentStatus.id === item.id) && (
						<div style={{ float: "right", color: "red" }}>
							<button type="submit" className="d-inline-flex align-items-center text-white btn btn-primary white" onClick={() => handleRequest(item.collection_month_request_id, 1, item.proposal_id)}>Accept</button>&nbsp;
							<button type="submit" className="d-inline-flex align-items-center text-white btn btn-primary white" onClick={() => handleRequest(item.collection_month_request_id, 2, item.proposal_id)}>Reject</button>
						</div>	
					)}</p>
				  </div>
				</div>
			  );
			})}


			<div className="text-right">
				{role_id === 1 && currentStatus.type === "proposal_accepted"  && (
					<button type="submit" className="d-inline-flex align-items-center text-white btn btn-primary" onClick={() => viewDetails(id)}>View And Send Final Details</button>
				)}
			</div>
			
			<div className="text-right">
				{role_id === 0 && (currentStatus.type === "send_final_details" || currentStatus.type === "correction_updated") && (
					<button type="submit" className="d-inline-flex align-items-center text-white btn btn-primary" onClick={() => viewConfirmationDetails(id)}>View Confirmation Details</button>
				)}
			</div>
			
			
			<div className="text-right">
				{role_id === 1 && currentStatus.type === "correction_request" && (
					<button type="button" className="d-inline-flex align-items-center text-white btn btn-primary" onClick={() => makeCorrection(id)}>Change Correction Details</button>
				)}
			</div>
			
			{ role_id === 1 && (currentStatus.type === "approved" || currentStatus.type === "chat")  &&
				<div className="text-right">
					<button type="button" className="d-inline-flex align-items-center text-white btn btn-primary" onClick={() => completeSavingCommitment(id)}>Complete Saving Commitment</button>
				</div>
			}
			
			
			{completedCollectionRequest.amount && (
			  <div className="shadow bg-gray-800 black text-white p-4 ms-md-5 ms-lg-6 mb-4 card border-0">
				<h5>Completion Details :</h5>
				<p className="fs-6 fw-bold">Submitted By : {completedCollectionRequest.savers_name}</p>
				<p className="fs-6 fw-bold">Approved Month : {completedCollectionRequest.collection_month}</p>
				<p className="fs-6 fw-bold">Monthly Savings Amount : £{completedCollectionRequest.amount}</p>
				<p className="fs-6 fw-bold">Savings Goal : {completedCollectionRequest.saving_goal}</p>
				<p className="fs-6 fw-bold">Date Completed : {completedCollectionRequest.created_at}</p>
			  </div>
			)}

			

			
				{/*
			<form className="mt-4 mb-5">
				<textarea required name="message" rows="6" maxLength="1000" placeholder="Your Message" className="border-0 shadow mb-4 form-control" value={message} onChange={updateMessage}></textarea>
				
				<div className="d-flex justify-content-between align-items-center mt-3">
					<div className="file-field"></div>
					<div><button type="button" className="d-inline-flex align-items-center text-white btn btn-primary" onClick={() => sendMessage(id)}> Reply</button></div>
				</div>
				
			</form>
				*/}
			
			
		</div>
	  </Container>
	  
	  <React.Fragment>
		  <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
			<Modal.Header>
			  <Modal.Title className="h6">Send proposal</Modal.Title>
			  <Button variant="close" aria-label="Close" onClick={handleClose} />
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xs={12} xl={12}>
						<Card border="light" className="bg-white shadow-sm mb-4">
						  <Card.Body>
							<h5 className="mb-4">Send proposal</h5>
							<Form onSubmit={handleSubmit}>
								<Col md={12} className="mb-3">
								  <Form.Group id="month">
									<Form.Label>Proposal month</Form.Label>
									<Form.Select defaultValue="" name="month" onChange={handleInputChange}>
									  <option value="">Choose Month</option>
									  <option value="1">January</option>
									  <option value="2">February</option>
									  <option value="3">March</option>
									  <option value="4">April</option>
									  <option value="5">May</option>
									  <option value="6">June</option>
									  <option value="7">July</option>
									  <option value="8">August</option>
									  <option value="9">September</option>
									  <option value="10">October</option>
									  <option value="11">November</option>
									  <option value="12">December</option>
									</Form.Select>
								  </Form.Group>
								  <div className="error-message">{errors.month}</div>
								</Col>
								<Col md={12} className="mb-3">
								  <Form.Group id="comment">
									<Form.Label>Comment </Form.Label>
									<Form.Control as="textarea" name="comment" placeholder="Enter Comment" onChange={handleInputChange} rows="3" />
								  </Form.Group>
								  <div className="error-message">{errors.comment}</div>
								</Col>
								<div className="mt-3 center">
									<Button variant="primary" name="save" data-button-name="save" type="submit">Send</Button>&nbsp;&nbsp;
									<Button variant="primary" onClick={handleClose}>Cancel</Button>&nbsp;
								  </div>
							</Form>
						  </Card.Body>
						</Card>
					</Col>
				  </Row>
			</Modal.Body>
		  </Modal>
		</React.Fragment>
		
		
		<React.Fragment>
			<Modal as={Modal.Dialog} centered show={showCommitmentDefault} onHide={handleCloseCommitment}>
				<Modal.Header>
				  <Modal.Title className="h6">Commitment Details</Modal.Title>
				  <Button variant="close" aria-label="Close" onClick={handleCloseCommitment} />
				</Modal.Header>
				
				<Modal.Body>
					<Row>
						<Col md={12}>
							<Col md={6} style={{float:'right'}}>
								<p>{commitmentData.name ?? ""}</p>
							</Col>
							<Col md={6}>
								<b>Member Name :</b>
							</Col>
						</Col>
						<Col md={12}>
							<Col md={6} style={{float:'right'}}>
								<p>{commitmentData.requested_month ?? ""}</p>
							</Col>
							<Col md={6}>
								<b>Collection Month :</b>
							</Col>
						</Col>
						<Col md={12}>
							<Col md={6} style={{float:'right'}}>
								<p>{commitmentData.amount ?? ""}</p>
							</Col>
							<Col md={6}>
								<b>Monthly Savings Amount :</b>
							</Col>
						</Col>
						<Col md={12}>
							<Col md={6} style={{float:'right'}}>
								<p>{commitmentData.saving_goal ?? ""}</p>
							</Col>
							<Col md={6}>
								<b>Savings Goal :</b>
							</Col>
						</Col>
						{commitmentData.commitment_details && (
						  <>
							<Col md={6} style={{ float: 'right' }}>
							  <p>{commitmentData.commitment_details}</p>
							</Col>
							<Col md={6}>
							  <b>Details:</b>
							</Col>
						  </>
						)}
						
						{role_id === 0 && (
						<Col md={12}>
							<Col md={6} style={{float:'right'}}>
								<Form.Control as="textarea" name="reason" placeholder="If the above details are incorrect, please provide the correct details" onChange={handleInputChangeIncorrectDetails} rows="3" />
							</Col>
							<Col md={6}>
								<b>Comment (If any) :</b>
							</Col>
						</Col>
						)}
						
						{currentStatus.type!= "complete" &&
							<div className="mt-3 center">
								{role_id === 1 && (
									<div>
										<Button variant="primary" onClick={sendFinalDetails}>Send Final Details</Button>&nbsp;
										<Button variant="primary" onClick={handleCloseCommitment}>Cancel</Button>
									</div>
								)}
								{role_id === 0 && (
									<div>
										<Button variant="primary" onClick={approveFinalDetails}>Accept</Button>{' '}
										<Button variant="primary" onClick={sendCorrectionDetails}>Send Correction</Button>{' '}
										<Button variant="primary" onClick={handleCloseCommitment}>Cancel</Button>
									</div>
								)}
							</div>
						}


					</Row>
				</Modal.Body>
				
			</Modal>
		</React.Fragment>
		
		
		
		<React.Fragment>
		  <Modal as={Modal.Dialog} centered show={correctRequest}  onHide={handleCorrectionRequest}>
			<Modal.Header>
			  <Modal.Title className="h6">Saving Commitment</Modal.Title>
			  <Button variant="close" aria-label="Close" onClick={handleClose} />
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xs={12} xl={12}>
						<Card border="light" className="bg-white shadow-sm mb-4">
						  <Card.Body>
							<h5 className="mb-4">Saving Commitment</h5>
							<Form onSubmit={handleSubmitCorrection}>
							  <Row>
								<Col md={12} className="mb-3">
									<Form.Group id="amount">
										<Form.Label>Savings Commitment Amount</Form.Label>
										<Form.Select  name="amount" onChange={handleInputChangeCommitments} defaultValue={formDataCommitments.amount}>
										  <option value="">Choose Commitment Amount</option>
										  {amount.map((amountData, index) => (
											<option key={index} value={amountData.amount}>
											  £{amountData.amount}
											</option>
										  ))}
										</Form.Select>
									</Form.Group>
									 <div className="error-message">{errorsCommitments.amount}</div>
								</Col>
								<Col md={12} className="mb-3">
									<Form.Group id="saving_goal">
										<Form.Label>Savings Goal</Form.Label>
										<Form.Select  name="saving_goal" onChange={handleInputChangeCommitments} defaultValue={formDataCommitments.saving_goal}>
										  <option value="">Choose Saving goal</option>
										  {commitmentgoal.map((goal, index) => (
											<option key={goal.id} value={goal.id}>
											  {goal.name}
											</option>
										  ))}
										</Form.Select>
									</Form.Group>
									<div className="error-message">{errorsCommitments.saving_goal}</div>
								</Col>
								<Col md={12} className="mb-3">
									<Form.Group id="collection_month">
										<Form.Label>Collection Month</Form.Label>
										<Form.Select  name="collection_month" onChange={handleInputChangeCommitments} defaultValue={formDataCommitments.requested_month}>
										    <option value="">Choose Collection Month</option>
										  {commitmentmonth.map((month, index) => (
											<option key={month.month_name} value={month.id}>
											  {month.month_name}
											</option>
										  ))}
										</Form.Select>

									</Form.Group>
								  <div className="error-message">{errorsCommitments.collection_month}</div>
								</Col>
							  </Row>
							  <div className="mt-3 center">
								<Button variant="primary" type="submit">Save</Button>
							  </div>
							</Form>
						  </Card.Body>
						</Card>
					</Col>
				  </Row>
			</Modal.Body>
		  </Modal>
		</React.Fragment>
		
	  
    </>
	
	
  );
};
