

import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Alert, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';



import BgImage from "../assets/img/illustrations/login_img.jpg";

import logoImage from "../assets/img/illustrations/logo.png";

import axios from 'axios';

import { useHistory, useParams } from 'react-router-dom';

import Loader from "../components/Loader";
import Preloader from "../components/Preloader";





export default () => {

	

   const [loading, setLoading] = useState(true);

   const [pageLoad, setPageLoad] = useState(true);   

   const { register_token } = useParams();

	

  const [formData, setFormData] = useState({

	first_name: '',

	last_name: '',   

    email: '',

    phone_number: '',

	address_1: '',

	address_2: '',   

    city: '',

    post_code: '',

	country: '',

	register_token:register_token,

	terms_conditions: false,

	is_registration_complete:'0'

  });



  const [errors, setErrors] = useState({

	first_name: '',

	last_name: '',  

    email: '',

    phone_number: '',

	address_1: '',

	address_2: '',   

    city: '',

    post_code: '',

	country: '',

	terms_conditions: '',

  });

  

  const [invalid, setInvalid] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const [countries, setCountry] = useState(false);

  

  const handleCheckboxChange = (event) => {

	  

	  setFormData({

      ...formData,

      ["terms_conditions"]: event.target.checked,

    });

	  

    setIsChecked(event.target.checked);

  };



  const handleInputChange = (e) => {

    const { name, value } = e.target;

    setFormData({

      ...formData,

      [name]: value,

    });

  };

  const history = useHistory();



  const handleSubmit = (e) => {  

    e.preventDefault();

    

    let valid = true;

    const newErrors = {

	  first_name: '',

	  last_name: '',

      email: '',

      phone_number: '',

	  address_1: '',

	  address_2: '',   

      city: '',

      post_code: '',

	  country: '',

	  terms_conditions: '',

    };

	

	

	if(formData.first_name === ""){

		newErrors.first_name = 'First Name field is required';

		valid = false;

	}

	

	if(formData.last_name === ""){

		newErrors.last_name = 'Last Name field is required';

		valid = false;

	}

	

	if(formData.email === ""){

		newErrors.email = 'Email field is required';

		valid = false;

	}



    if (!formData.email || !formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {

      newErrors.email = 'Please enter a valid email address';

      valid = false;

    }

	

	if(formData.phone_number === ""){

		newErrors.phone_number = 'Phone Number field is required';

		valid = false;

	}



    if (!formData.phone_number || formData.phone_number.length < 10) {

      newErrors.phone_number = 'Enter valid Phone Number';

      valid = false;

    }

	

	if(formData.post_code === "" || formData.post_code === null){

		newErrors.post_code = 'Post Code field is required';

		valid = false;

	}

	

	if(formData.post_code === ""){

		newErrors.post_code = 'Post Code field is required';

		valid = false;

	}else{

		const ukPostcodeRegex = /^[a-zA-Z0-9\s]+$/;

		if(!ukPostcodeRegex.test(formData.post_code)){

			newErrors.post_code = 'Please enter a valid post code. (e.g. D1234GH)';

			valid = false;

		}

	}

	

	if(formData.country === ""){

		newErrors.country = 'Country field is required';

		valid = false;

	}
	
	
	if(formData.city === ""){

		newErrors.city = 'City field is required';

		valid = false;

	}
	
	
	if(formData.address_1 === ""){

		newErrors.address_1 = 'Address 1 field is required';

		valid = false;

	}
	
	if(formData.address_2 === ""){

		newErrors.address_2 = 'Address 2 field is required';

		valid = false;

	}

	

	if(formData.terms_conditions === false){

		newErrors.terms_conditions = 'Please Accept Terms & Conditions.';

		valid = false;

	}



    setErrors(newErrors);



    if (valid) {

		

		setLoading(true);

		

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/users/updateDetails'; 

			

		axios.post(apiBaseURL, formData, {

		})

		.then(({ data }) => {

			if(data.status==="success"){

				setLoading(false);

				history.push(`/setup-password/${register_token}`);

			}

		}).catch(error => {

		});

    }

  };



	useEffect(()=>{

		

		const apiBaseURL1 = process.env.REACT_APP_API_BASE_URL+'/country_list';  

	 

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/users/getDetails';  

						 

		const formData1 = new FormData();

		formData1.append('register_token', register_token);	 

						 

		axios.post(apiBaseURL, formData1, {

		})

		.then(({ data }) => {

			if(data.status==="success"){

			  

			  setFormData({

			  ...formData,

			  ["first_name"]: data.data.first_name,

			  ["last_name"]: data.data.last_name,

			  ["email"]: data.data.email,

			  ["phone_number"]: data.data.phone_number,

			  ["address_1"]: data.data.address_1,

			  ["address_2"]: data.data.address_2,

			  ["city"]: data.data.city,

			  ["post_code"]: data.data.post_code,

			  ["country"]: data.data.country,

			  ["is_registration_complete"]: data.data.is_registration_complete,

			  });

			}

			console.log("sdfsdfsd",data.data.is_registration_complete);

			if(data.data.is_registration_complete === "1"){

				history.push("/");

			}

			

			axios.get(apiBaseURL1, {})

			.then(({ data }) => {

				if(data.status==="success"){

					setCountry(data.data);

					setLoading(false);

					setPageLoad(false);

				}

			}).catch(error => {

			});

			

		}).catch(error => {

			setLoading(false);

		});

	

	},[]);



  

   const divStyle = {

    height: '100%', // Note: Make sure to enclose 100% in quotes

    

    left: 0,

    top: 0,

    overflow: 'hidden', // Note: Make sure to enclose 'hidden' in quotes

    position: 'fixed'   // Note: Make sure to enclose 'fixed' in quotes

  };

  

  if (loading && pageLoad) {

	return <div>Loading...</div>;

  }

  

  return (

	<>

	{loading && !pageLoad && <Preloader show={true} />}

    <main>

      <section className="d-flex align-items-center p-0">

        <Container fluid className="p-0">

          <Row className="m-0 user-area-section register-page" style={ divStyle }>

            <Col md={8} className="p-0"> 

              <div className="form-bg-image">

                <img src={BgImage} alt="Background" className="w-100 h-100" />

              </div>

            </Col>

            <Col md={4} className="bg-white">

              <div className="bg-white p-0 p-lg-4 mg-b">

                <div className="text-center text-md-center mb-4 mt-md-0">

                  <img src={logoImage} alt="Background" style={{ height: '100px' }} />

                  <h5 className="mb-0">Register Your Account</h5>

                </div>

				{invalid && 

				<Alert variant="danger">

					please enter a valid user name and/or password to login

				</Alert>

				}

                <Form className="mt-4" onSubmit={handleSubmit}>

				

				   <Row>

					<Col md={12} className="mb-3">

					  <Form.Group id="firstName">

						<Form.Control type="text" name="first_name" placeholder="First Name" onChange={handleInputChange} value={formData.first_name} />

					  </Form.Group>

					  <div className="error-message">{errors.first_name}</div>

					</Col>

					<Col md={12} className="mb-3">

					  <Form.Group id="lastName">

						<Form.Control type="text" name="last_name" placeholder="Last Name" onChange={handleInputChange} value={formData.last_name}/>

					  </Form.Group>

					  <div className="error-message">{errors.last_name}</div>

					</Col>

				  </Row>

				  <Row>

					<Col md={12} className="mb-3">

					  <Form.Group id="phone">

						<Form.Control type="text" name="phone_number" placeholder="Phone Number" onChange={handleInputChange} value={"+"+formData.phone_number}/>

					  </Form.Group>

					  <div className="error-message">{errors.phone_number}</div>

					</Col>

					<Col md={12} className="mb-3">

					  <Form.Group id="email">

						<Form.Control type="email" name="email" placeholder="Email" onChange={handleInputChange} value={formData.email} readOnly />

					  </Form.Group>

					  <div className="error-message">{errors.email}</div>

					</Col>

				  </Row>

				  

				  <Row>

					<Col md={12} className="mb-3">

					  <Form.Group id="address_1">

						<Form.Control type="text" name="address_1" placeholder="Address Line 1" value={formData.address_1} onChange={handleInputChange} />

					  </Form.Group>

					  <div className="error-message">{errors.address_1}</div>

					</Col>

				  </Row>

				  

				  <Row>

					<Col md={12} className="mb-3">

					  <Form.Group id="address_2">

						<Form.Control type="text" name="address_2" placeholder="Address Line 2" value={formData.address_2} onChange={handleInputChange} />

					  </Form.Group>

					  <div className="error-message">{errors.address_2}</div>

					</Col>

				  </Row>

				  

				  <Row>

					<Col md={12} className="mb-3">

					  <Form.Group id="city">

						<Form.Control type="text" name="city" placeholder="City" value={formData.city} onChange={handleInputChange} />

					  </Form.Group>

					  <div className="error-message">{errors.city}</div>

					</Col>

					<Col md={12} className="mb-3">

					  <Form.Group id="emal">

						<Form.Control type="text" name="post_code" placeholder="Post Code*" value={formData.post_code} onChange={handleInputChange} />

					  </Form.Group>

					  <div className="error-message">{errors.post_code}</div>

					</Col>

				  </Row>

				  

				  <Row>

					<Col md={12} className="mb-3">

					  <Form.Group id="country">

						<Form.Select defaultValue="UNITED KINGDOM" name="country" onChange={handleInputChange}>

						  <option value="">Choose Country</option>
						  
							{countries.map((goal, index) => (
								(goal == 'UNITED KINGDOM') ?
									<option key={goal} value={goal}>
										{goal}
									</option> : ""
							))}

						</Form.Select>

					  </Form.Group>

					  

					  <div className="error-message">{errors.country}</div>

					</Col>

				  </Row>

				

				  <Row>

					<Col md={12} className="mb-3">

					  <Form.Group>

						<div className="d-flex justify-content-between align-items-center mb-1">

						  <Form.Check type="checkbox">

							<FormCheck.Input id="defaultCheck5" name="terms_conditions" className="me-2" onChange={handleCheckboxChange} checked={isChecked}/><span>By clicking this button, You accept the Terms & Conditions of this website</span>

						  </Form.Check>

						</div>

						<div className="error-message">{errors.terms_conditions}</div>

					  </Form.Group>

				  </Col>

				  </Row>

				  

				  <div class="center">

                  <Button variant="primary" type="submit" className="btn btn-secondry">

                    Create My Account

                  </Button>

				  </div>

                </Form>

              </div>

			  <div className="bg-white p-0 p-lg-4 center">

				<span class="mt-4 Copyright">Copyright @ 2023 SavveySavers</span>

			  </div>

            </Col>

          </Row>

        </Container>

      </section>      

    </main>

	</>

  );

};

