import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield, faHandshake, faUser } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, Alert ,ButtonGroup, Table, Card, Modal, Form } from '@themesberg/react-bootstrap';
import { faDesktop, faMobileAlt, faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { CounterWidget, CounterWidget1, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { PageVisitsTable } from "../../components/VisitsTables";
import { trafficShares, totalOrders } from "../../data/charts";
import Loader from "../../components/Loader";
import Preloader from "../../components/Preloader";
import { useHistory } from 'react-router-dom';
import axios from 'axios';

export default () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState([]);
	const [userList, setUsersList] = useState([]);
	const [savingAmount, setSavingAmount] = useState(0);
	const [totalPayment, setTotalPayment] = useState(0);
	const [releasedHarvest, setReleasedHarvest] = useState(0);
	const [totalreleasedHarvest, setTotalreleasedHarvest] = useState(0);
	const [totalsavingCommitments, setTotalsavingCommitments] = useState(0);
	const [totalactive, setTotalActive] = useState(0);
	const [totalinvited, setTotalInvited] = useState(0);
	const [monthlyPaid, setMonthlyPaid] = useState(0);
	const [monthlyUnpaid, setMonthlyUnpaid] = useState(0);
	const [mysavingCommitments, setMysavingCommitments] = useState([]);
	const [totalrevenu, settotalrevenu] = useState(0);
	const [chartdataa, setchartdataa] = useState([]);
	const [mobileNumber, setmobileNumber] = useState("+44");
	const [error, setError] = useState(null);
	const history = useHistory();
	const [notificationList, setNotificationList] = useState([]);
	const [savingCommitmentList, setSavingCommitmentList] = useState([]);
	const [savingPaymentRecords, setSavingCommitmentRecords] = useState([]);

	const [showDefault, setShowDefault] = useState(false);
	const handleClose = () => setShowDefault(false);

	// Get Dashboard Data
	const storedUser = localStorage.getItem('user');

	if (!storedUser) {
		// Redirect to the login URL
		history.push('/'); // Replace '/login' with the actual URL of your login page
		return false;
	}

	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;
	const user_id = userObject.user_id;
	const role_id = userObject.role_id;

	const fetchData = async page => {
		setLoading(true);
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/dashboard';

		const formData = new FormData();
		formData.append('user_id', user_id);

		axios.post(apiBaseURL, formData, {
			headers: {
				Authorizations: accessToken // Include the access token in the Authorization header
			}
		})
			.then(({ data }) => {
				if (data.status === "success") {
					setUsers(data.trafficShares);
					setUsersList(data.invitedUsersByList);
					setSavingAmount(data.savingAmount);
					setSavingCommitmentList(data.savingRecords);
					setSavingCommitmentRecords(data.savingPaymentRecords);
					settotalrevenu(data.totalrevenue);
					setTotalreleasedHarvest(data.totalreleasedHarvest);
					setTotalsavingCommitments(data.totalsavingCommitments);
					// const cdata = JSON.parse();
					setchartdataa(data.chartdata);
					setReleasedHarvest(data.releasedHarvest);
					setMysavingCommitments(data.mysavingCommitments);
					setTotalPayment(data.totalPayment);
					setTotalInvited(data.totalinvited);
					setTotalActive(data.totalactive);
					setMonthlyPaid(data.monthlyPaid);
					setMonthlyUnpaid(data.monthlyUnpaid);
				}
				setLoading(false);
			}).catch(error => {
				setLoading(false);
			});

	};


	useEffect(() => {
		fetchData();
	}, []);



	useEffect(()=>{
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/get_notification';  
		
		const formData = new FormData();
	  
		axios.get(apiBaseURL, {
		  params: formData, // Pass formData as query parameters (optional, adjust based on your API endpoint requirements)
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		})
		  .then(response => {
			if (response.data.status === "success") {
			  setNotificationList(response.data.data);
			} else {
			  // Handle other response statuses if needed
			}
		  })
		  .catch(error => {
			// Handle errors here
			console.error('Error fetching data:', error);
		  });
		
	},[]);


	// Create Users
	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone_number: '',
		buttonName: ''
	});

	const [errors, setErrors] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone_number: '',
	});

	const [invalid, setInvalid] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		if (name == "phone_number") {
			setmobileNumber(value);
		}
		setFormData({
			...formData,
			[name]: value,
		});

	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Get the name of the button clicked
		const buttonName = e.nativeEvent.submitter.name;

		const formsbutton = formData['buttonName'] = buttonName;

		//console.log(formData);
		// Validate email and password
		let valid = true;
		const newErrors = {
			first_name: '',
			last_name: '',
			email: '',
			phone_number: '',
		};

		if (!formData.email || !formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
			newErrors.email = 'Please enter a valid email address';
			valid = false;
		}

		if (formData.email === "") {
			newErrors.email = 'Email field is required';
			valid = false;
		}
		if (formData.first_name === "") {
			newErrors.first_name = 'First Name field is required';
			valid = false;
		}
		if (formData.last_name === "") {
			newErrors.last_name = 'Last Name field is required';
			valid = false;
		}
		if (formData.phone_number === "") {
			newErrors.phone_number = 'Phone Number field is required';
			valid = false;
		} else {
			const ukMobileNumberRegex = /^(?:0|\+44)(?:\d\s?){9,10}$/;
			if (!ukMobileNumberRegex.test(formData.phone_number)) {
				newErrors.phone_number = 'Phone Number must be a valid number. (UK eg.+447975556677)';
				valid = false;
			}
		}
		setErrors(newErrors);

		if (valid) {
			setLoading(true);
			const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users/create';

			axios.post(apiBaseURL, formData, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})
				.then(({ data }) => {
					if (data.status === "success") {
						setShowDefault(false);
						fetchData();
						setError(null);
						setLoading(false);
					}

					if (data.status === "error") {

						setError('The email is already in use');

						setLoading(false);

					}
				}).catch(error => {
					setLoading(false);
				});

		}
	};
	// End Create Users

	const btnWidth = {
		width: '125px'
	};


	if (loading) {
		//return <div>Loading...</div>;
	}
	console.log('dashbla',chartdataa);

	return (
		<>
			{loading && <Preloader show={true} />}
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">

			</div>

			{role_id != 1 && (
				<Row>
					<Col xl={6} className="mb-4">
						<CounterWidget1
							category="Saving Commitment"
							title={mysavingCommitments.length}
							period=""
							percentage=""
							icon={faHandshake}
							data_record={mysavingCommitments}
							startAt = "1"
							iconColor="shape-secondary"
						/>
					</Col>
					<Col xl={6} className="mb-4">
						<CounterWidget
							category="Total Invitations"
							title={userList.length}
							period=""
							percentage=""
							icon={faUser}
							iconColor="shape-secondary"
						/>
					</Col>
					<Col xl={6} className="mb-4">
						<CounterWidget
							category="Harvest to Date"
							title={`${releasedHarvest} of ${mysavingCommitments.length}`}
							period=""
							percentage=""
							icon={faChartLine}
							iconColor="shape-secondary"
						/>
					</Col>
					<Col xl={6} className="mb-4">
						<CounterWidget
							category="Total Saved to Date"
							title={savingAmount}
							period=""
							percentage=""
							icon={faCashRegister}
							iconColor="shape-tertiary"
						/>
					</Col>
				</Row>
			)}

			{role_id === 1 && (
				<Row>
				
					<Col xl={6} className="mb-4 NewWidth">
						<CounterWidget
							category="Revenue to date"
							title={"£" + totalPayment}
							period=""
							percentage=""
							icon={faCashRegister}
							iconColor="shape-secondary"
						/>
					</Col>
					<Col xl={6} className="mb-4 NewWidth">
						<CounterWidget
							category="Payments not yet Confirmed"
							title={`${monthlyUnpaid} / ${monthlyPaid}`}
							period=""
							percentage=""
							icon={faUser}
							iconColor="shape-secondary"
						/>
					</Col>
					<Col xl={6} className="mb-4 NewWidth">
						<CounterWidget
							category="Harvest Released to Date"
							title={`${totalreleasedHarvest} of ${totalsavingCommitments}`}
							period=""
							percentage=""
							icon={faChartLine}
							iconColor="shape-secondary"
						/>
					</Col>
					<Col xl={6} className="mb-4 NewWidth">
						<CounterWidget
							category="Active Users Vs Invited"
							title={`${totalactive} / ${totalinvited}`}
							period=""
							percentage=""
							icon={faUser}
							iconColor="shape-tertiary"
						/>
					</Col>
				
					<Col xs={12} xl={12} className="mb-4">
						<Row>
							<Col xs={12} xl={6}>
								<Row>
									<Col xs={12} className="mb-4">
										<BarChartWidget
											title="Total Revenue"
											value={"£" + totalrevenu}
											data={totalOrders} 
											chartdata={chartdataa}/>
									</Col>
								</Row>
							</Col>
							<Col xs={12} xl={6} className="mb-4">
								<CircleChartWidget
									title="Users"
									data={users} />
							</Col>
						</Row>
					</Col>
				</Row>
			)}

			{role_id != 1 && (
				<Row>
					<Col xs={12} xl={12} className="mb-4">
						<Row>
						{/*
							<Col xs={12} className="mb-4">
								<Card border="light" className="shadow-sm">
									<Card.Header>
										<Row className="align-items-center">
											<Col xs={8}>
												<h5>Savings Commitment</h5>
											</Col>
										</Row>

									</Card.Header>
									<div class="dashboardTable">
										<Table responsive className="align-items-center table-flush">
											<thead className="thead-light">
												<tr>
													<th scope="col">Sr.No.</th>
													<th scope="col">Saving Goal</th>
													<th scope="col">No. of installment</th>
													<th scope="col">Amount</th>
												</tr>
											</thead>
											<tbody>
												{savingPaymentRecords.length > 0 ? (
													savingPaymentRecords.map((paymentRecords, index) => {
														const createdAtDate = new Date(paymentRecords.created_at);
														const formattedCreatedAt = `${createdAtDate.getFullYear()}-${String(createdAtDate.getMonth() + 1).padStart(2, '0')}-${String(createdAtDate.getDate()).padStart(2, '0')} ${String(createdAtDate.getHours()).padStart(2, '0')}:${String(createdAtDate.getMinutes()).padStart(2, '0')}:${String(createdAtDate.getSeconds()).padStart(2, '0')}`;

														return (
															<tr key={paymentRecords.id}>
																<td scope="col">{index + 1}</td>
																<td scope="col">{paymentRecords.saving_goal}</td>
																<td scope="col">{paymentRecords.total_payments}</td>
																<td scope="col">£ {paymentRecords.total_payments * paymentRecords.amount}</td>
															</tr>
														);
													})
												) : (
													<tr>
														<td colSpan="6" className="center">No records found</td>
													</tr>
												)}
											</tbody>
										</Table>
									</div>
								</Card>
							</Col>
						
						
							<Col xs={12} className="mb-4">
								<Card border="light" className="shadow-sm">
									<Card.Header>
										<Row className="align-items-center">
											<Col xs={8}>
												<h5>Harvest Month</h5>
											</Col>
										</Row>

									</Card.Header>
									<div class="dashboardTable">
										<Table responsive className="align-items-center table-flush">
											<thead className="thead-light">
												<tr>
													<th scope="col">Sr.No.</th>
													<th scope="col">Saving Commitment</th>
													<th scope="col">Commitment Month</th>
												</tr>
											</thead>
											<tbody>
												{savingCommitmentList.length > 0 ? (
													savingCommitmentList.map((savingList, index) => {
														const createdAtDate = new Date(savingList.created_at);
														const formattedCreatedAt = `December ${createdAtDate.getFullYear()}`;

														return (
															<tr key={savingList.id}>
																<td scope="col">{index + 1}</td>
																<td scope="col">{savingList.saving_goal}</td>
																<td scope="col">{formattedCreatedAt}</td>
															</tr>
														);
													})
												) : (
													<tr>
														<td colSpan="6" className="center">No records found</td> 
													</tr>
												)}
											</tbody>
										</Table>
									</div>
								</Card>
							</Col>
							
							
							<Col xs={12} className="mb-4">
								<Card border="light" className="shadow-sm">
									<Card.Header>
										<Row className="align-items-center">
											<Col xs={8}>
												<h5>Notification</h5>
											</Col>
										</Row>

									</Card.Header>
									<div class="dashboardTable">
										<Table responsive className="align-items-center table-flush">
											<thead className="thead-light">
												<tr>
													<th scope="col">Sr.No.</th>
													<th scope="col">Title</th>
													<th scope="col">Time</th>
												</tr>
											</thead>
											<tbody>
												{notificationList.length > 0 ? (
													notificationList.map((notifications, index) => {
														const createdAtDate = new Date(notifications.created_at);
														const formattedCreatedAt = `${createdAtDate.getFullYear()}-${String(createdAtDate.getMonth() + 1).padStart(2, '0')}-${String(createdAtDate.getDate()).padStart(2, '0')} ${String(createdAtDate.getHours()).padStart(2, '0')}:${String(createdAtDate.getMinutes()).padStart(2, '0')}:${String(createdAtDate.getSeconds()).padStart(2, '0')}`;

														return (
															<tr key={notifications.id}>
																<td scope="col">{index + 1}</td>
																<td scope="col" >{notifications.message.length > 170 ? `${notifications.message.slice(0, 170)}...` : notifications.message}</td>
																<td scope="col">{formattedCreatedAt}</td>
															</tr>
														);
													})
												) : (
													<tr>
														<td colSpan="6" className="center">No records found</td>
													</tr>
												)}
											</tbody>
										</Table>
									</div>
								</Card>
							</Col>
							
							
							<Col xs={12} className="mb-4">
								<Card border="light" className="shadow-sm">
									<Card.Header>
										<Row className="align-items-center">
											<Col xs={8}>
												<h5>Invited Members</h5>
											</Col>
											<Col xs={4} className="ps-md-0 text-end">
												<ButtonGroup>
													<Button variant="primary" className="m-1" size="sm" onClick={() => setShowDefault(true)}>Invite Member</Button>
												</ButtonGroup>
											</Col>
										</Row>

									</Card.Header>
									<div class="dashboardTable">
										<Table responsive className="align-items-center table-flush">
											<thead className="thead-light">
												<tr>
													<th scope="col">Sr.No.</th>
													<th scope="col">Name</th>
													<th scope="col">Email</th>
													<th scope="col">Phone Number</th>
													<th scope="col">Invited On</th>
													<th scope="col">Status</th>
												</tr>
											</thead>
											<tbody>
												{userList.length > 0 ? (
													userList.map((user, index) => {
														const createdAtDate = new Date(user.created_at);
														const formattedCreatedAt = `${createdAtDate.getFullYear()}-${String(createdAtDate.getMonth() + 1).padStart(2, '0')}-${String(createdAtDate.getDate()).padStart(2, '0')} ${String(createdAtDate.getHours()).padStart(2, '0')}:${String(createdAtDate.getMinutes()).padStart(2, '0')}:${String(createdAtDate.getSeconds()).padStart(2, '0')}`;

														return (
															<tr key={user.id}>
																<td scope="col">{index + 1}</td>
																<td scope="col">{user.name}</td>
																<td scope="col">{user.email}</td>
																<td scope="col">{user.phone_number}</td>
																<td scope="col">{formattedCreatedAt}</td>
																<td scope="col"><button type="button" className="m-1 btn btn-primary btn-sm">{user.is_registration_complete === "1" ? "Active" : "Inactive"}</button></td>
															</tr>
														);
													})
												) : (
													<tr>
														<td colSpan="6" className="center">No records found</td> 
													</tr>
												)}
											</tbody>
										</Table>
									</div>
								</Card>
							</Col>
							*/}
						</Row>
					</Col>
				</Row>
			)}



			<React.Fragment>
				<Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
					<Modal.Header>
						<Modal.Title className="h6">Invite User</Modal.Title>
						<Button variant="close" aria-label="Close" onClick={handleClose} />
					</Modal.Header>
					<Modal.Body>
						<Row>
							{error &&

								<Alert variant="danger">

									{error}

								</Alert>

							}
							<Col xs={12} xl={12}>
								<Card border="light" className="bg-white shadow-sm mb-4">
									<Card.Body>
										<h5 className="mb-4">Invite User</h5>
										<Form onSubmit={handleSubmit}>
											<Row>
												<Col md={6} className="mb-3">
													<Form.Group id="firstName">
														<Form.Label>First Name<span class="error-message">*</span></Form.Label>
														<Form.Control type="text" name="first_name" placeholder="Enter your first name" onChange={handleInputChange} />
													</Form.Group>
													<div className="error-message">{errors.first_name}</div>
												</Col>
												<Col md={6} className="mb-3">
													<Form.Group id="lastName">
														<Form.Label>Last Name<span class="error-message">*</span></Form.Label>
														<Form.Control type="text" name="last_name" placeholder="Also your last name" onChange={handleInputChange} />
													</Form.Group>
													<div className="error-message">{errors.last_name}</div>
												</Col>
											</Row>
											<Row>
												<Col md={6} className="mb-3">
													<Form.Group id="emal">
														<Form.Label>Email<span class="error-message">*</span></Form.Label>
														<Form.Control type="email" name="email" placeholder="name@company.com" onChange={handleInputChange} />
													</Form.Group>
													<div className="error-message">{errors.email}</div>
												</Col>
												<Col md={6} className="mb-3">
													<Form.Group id="phone">
														<Form.Label>Phone<span class="error-message">*</span></Form.Label>
														<Form.Control type="text" name="phone_number" placeholder="+447975556677" onChange={handleInputChange} value={mobileNumber} />
													</Form.Group>
													<div className="error-message">{errors.phone_number}</div>
												</Col>
											</Row>
											<div className="mt-3 center">
												<Button variant="primary" name="invite" data-button-name="invite" type="submit" style={btnWidth}>Save & Invite</Button>&nbsp;&nbsp;
												<Button variant="primary" onClick={handleClose} style={btnWidth}>Cancel</Button>&nbsp;
											</div>
										</Form>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Modal.Body>
				</Modal>
			</React.Fragment>


		</>
	);
};
