import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';

import Preloader from "../components/Preloader";

import { Link, useHistory } from 'react-router-dom';

import { Routes } from "../routes";

import transactions from "../data/transactions";

import axios from 'axios';

import Swal from 'sweetalert2';

import Loader from "../components/Loader";



const ValueChange = ({ value, suffix }) => {

	const valueIcon = value < 0 ? faAngleDown : faAngleUp;

	const valueTxtColor = value < 0 ? "text-danger" : "text-success";



	return (

		value ? <span className={valueTxtColor}>

			<FontAwesomeIcon icon={valueIcon} />

			<span className="fw-bold ms-1">

				{Math.abs(value)}{suffix}

			</span>

		</span> : "--"

	);

};







export const SecurityTable = (searchKey) => {



	const [showDefault, setShowDefault] = useState(false);

	const handleClose = () => setShowDefault(false);



	const [data, setData] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);

	const [startPage, setStartPage] = useState(1);

	const [endPage, setEndPage] = useState(1);

	const [totalRecord, setTotalRecord] = useState(1);

	const [perPage, setPerPage] = useState(1);

	const [totalPages, setTotalPages] = useState(1);

	const [search, setSearch] = useState("");

	const [loading, setLoading] = useState(true);





	{/*Sorting*/ }

	const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

	function truncateString(str, maxLength) {
		if (str.length > maxLength) {
		  return str.substr(0, maxLength) + "...";
		}
		return str;
	  }




	const handleSort = (key) => {

		let direction = 'asc';

		if (sortConfig.key === key && sortConfig.direction === 'asc') {

			direction = 'desc';

		}

		setSortConfig({ key, direction });



		const storedUser = localStorage.getItem('user');

		const userObject = JSON.parse(storedUser);

		const accessToken = userObject.access_token;

		const user_id = userObject.user_id;



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/securty';



		const formData = new FormData();

		formData.append('page', 1);

		formData.append('search', search);

		formData.append('sortBy', key);

		formData.append('orderBy', direction);



		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorization: `Bearer ${accessToken}`, // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};





	const getSortIcon = (columnKey) => {

		if (sortConfig.key === columnKey) {

			return sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;

		}

		return <FontAwesomeIcon icon={faSort} />;

	};



	{/*End Sorting*/ }





	const fetchData = async page => {



		const storedUser = localStorage.getItem('user');

		const userObject = JSON.parse(storedUser);

		const accessToken = userObject.access_token;



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/securty';



		const formData = new FormData();

		formData.append('page', page);

		formData.append('search', search);



		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorization: `Bearer ${accessToken}`, // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};






	useEffect(() => {

		setSearch(searchKey?.data);

	}, [searchKey?.data]);

	useEffect(() => {
		fetchData(currentPage);
	}, [search]);





	const handlePageChange = page => {

		setCurrentPage(page);

	};



	const onPageChange = (pageNo) => {

		setCurrentPage(pageNo);

		fetchData(pageNo);

	};



	if (loading) {

		//return <div>Loading...</div>;

	}



	const items = [];

	for (let i = 0; i < totalPages; i++) {

		items.push(i + 1);

	}





	const totalUsers = data.length;



	const srStart = (currentPage - 1) * perPage;



	const modifiedData = data.map((t, index) => ({

		...t,

		serialNumber: srStart + index + 1 // Assuming the serial number starts from 1

	}));



	const prevPage = currentPage > 1 ? currentPage - 1 : 1;

	const nextPage = currentPage < totalPages ? currentPage + 1 : totalPages;





	const TableRow = (props) => {

		const { id, question_name, created_at, serialNumber } = props;
		
		// const dateObject = new Date(created_at);
		// const formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')} ${dateObject.getHours().toString().padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}:${dateObject.getSeconds().toString().padStart(2, '0')}`;
		const timezone = 'Europe/London'; // Replace with your desired timezone

		const createdAtDate = new Date(created_at);
		const options = { timeZone: timezone };

		// Format created_at as DD-MM-YYYY HH:mm:ss in the specified timezone
		const formattedDate = createdAtDate.toLocaleString('en-US', options);


		const storedUser = localStorage.getItem('user');

		const userObject = JSON.parse(storedUser);

		const accessToken = userObject.access_token;

		const history = useHistory();

		const handleDelete = () => {

			const formData = new FormData();

			formData.append('id', id);

			// Show a SweetAlert confirmation dialog

			Swal.fire({

				title: 'Delete Item',

				text: 'Are you sure you want to delete this item?',

				icon: 'warning',

				showCancelButton: true,

				confirmButtonText: 'Delete',

				cancelButtonText: 'Cancel',

				confirmButtonColor: '#262B40',

			}).then((result) => {

				if (result.isConfirmed) {

					const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/security/delete';

					axios.post(apiBaseURL, formData, {

						headers: {

							Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

						}

					})

						.then(({ data }) => {

							if (data.status === "success") {

								history.push('/security_questions');

								setShowDefault(false);

								window.location.reload();

							}

							//setLoading(false);

						}).catch(error => {

							//setLoading(false);

						});

				}

			});

		};

		return (

			<tr>

				<td>

					<span className="fw-normal">

						{serialNumber}

					</span>

				</td>

				<td>
					<span className="fw-normal">
						{truncateString(question_name,50)}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{formattedDate}
					</span>
				</td>

				<td>
					<Dropdown as={ButtonGroup}>
						<Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
							<span className="icon icon-sm">
								<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
							</span>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item className="text-primary" onClick={handleDelete}>
								<FontAwesomeIcon icon={faTrashAlt} className="me-2"  />Delete
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>

			</tr>

		);

	};



	return (

		<>

			{loading && <Preloader show={true} />}

			<Card border="light" className="table-wrapper table-responsive shadow-sm">

				<Card.Body className="pt-0">

					<Table hover className="user-table align-items-center">

						<thead>

							<tr>

								<th className="border-bottom notsort" onClick={() => handleSort('id')}>Sr.No. {getSortIcon('id')}</th>

								<th className="border-bottom" onClick={() => handleSort('question_name')}>Question Name {getSortIcon('question_name')}</th>

								<th className="border-bottom" onClick={() => handleSort('created_at')}>Created At {getSortIcon('created_at')}</th>

								<th className="border-bottom" >Action</th>
							</tr>

						</thead>

						<tbody>

							{modifiedData.map(t => <TableRow key={`data-${t.id}`} {...t} />)}

						</tbody>

					</Table>

					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">

						<Nav>

							<Pagination className="mb-2 mb-lg-0">

								<Pagination.Prev onClick={() => onPageChange(prevPage)}>

									Previous

								</Pagination.Prev>



								{items.map((item, index) => (

									<Pagination.Item active={item === currentPage} onClick={() => onPageChange(item)}>{item}</Pagination.Item>

								))}



								<Pagination.Next onClick={() => onPageChange(nextPage)}>

									Next

								</Pagination.Next>

							</Pagination>

						</Nav>

						<small className="fw-bold">

							Showing <b>{totalUsers}</b> out of <b>{totalRecord}</b> entries

						</small>

					</Card.Footer>

				</Card.Body>

			</Card>

		</>

	);

};