

import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Alert, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';



import BgImage from "../assets/img/illustrations/login_img.jpg";

import logoImage from "../assets/img/illustrations/logo.png";

import axios from 'axios';

import { useHistory, Link } from 'react-router-dom';

import Loader from "../components/Loader";

import { Routes } from "../routes";
import Swal from 'sweetalert2';






export default () => {



  const [loading, setLoading] = useState(false);



  const [formData, setFormData] = useState({

    email: '',

    password: '',

  });



  const [errors, setErrors] = useState({

    email: '',

    password: '',

  });



  const [invalid, setInvalid] = useState(false);



  const handleInputChange = (e) => {

    const { name, value } = e.target;

    setFormData({

      ...formData,

      [name]: value,

    });

  };

  const history = useHistory();



  const handleSubmit = (e) => {

    e.preventDefault();

    // Validate email and password

    let valid = true;

    const newErrors = {

      email: '',

      password: '',

    };



    if (!formData.email || !formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {

      newErrors.email = 'Please enter a valid email address';

      valid = false;

    }



    if (formData.email === "") {

      newErrors.email = 'Email field is required';

      valid = false;

    }





    setErrors(newErrors);



    if (valid) {

      setLoading(true);

      // Read the environment variable

      const apiBaseURL = process.env.REACT_APP_API_BASE_URL;



      // Perform login logic here, for example, sending data to an API

      axios.defaults.baseURL = apiBaseURL;

      axios.post('/forgotPassword', formData)

        .then(({ data }) => {

          if (data.status === "success") {

            setLoading(false);

        
            Swal.fire('Your request has been processed.If you have an account associated with that email address,you will receive an email with instructions on how to reset your password.', '', 'success').then(() => {
              history.push('/');
            })

          
            // Redirect to another route (e.g., dashboard)

          } else {

            setInvalid(true);

          }

        }).catch(error => {
          setLoading(false);
          Swal.fire('This user is not exist!', '', 'error').then(() => {
          })
          setInvalid(true);

        });

    }

  };

  const divStyle = {

    height: '100%', // Note: Make sure to enclose 100% in quotes



    left: 0,

    top: 0,

    overflow: 'hidden', // Note: Make sure to enclose 'hidden' in quotes

    position: 'fixed'   // Note: Make sure to enclose 'fixed' in quotes

  };



  const btnWidth = {

    width: '200px'

  };



  const storedUser = localStorage.getItem('user');

  if (storedUser) {

    console.log('data found');

    history.push('/dashboard');

  } else {

    console.log('data Not found');

    //history.push('/');

  }

  return (

    <>

      {loading && <Loader />}

      <main>

        <section className="d-flex align-items-center p-0">

          <Container fluid className="p-0">

            <Row className="m-0 user-area-section" style={divStyle}>

              <Col md={8} className="p-0">

                <div className="form-bg-image" style={{ height: '100%' }}>

                  <img src={BgImage} alt="Background" className="w-100 h-100" />

                </div>

              </Col>

              <Col md={4} className="bg-white">

                <div className="login-block">

                  <div className="bg-white p-0 p-lg-4 mg-b">

                    <div className="text-center text-md-center mb-4 mt-md-0">

                      <img src={logoImage} alt="Background" style={{ height: '100px' }} />

                      <h5 className="mb-0">Reset Password</h5>

                    </div>

                    <p><b>Please enter your email address to reset your password</b></p>



                    <Form className="mt-4" onSubmit={handleSubmit}>

                      <Form.Group id="email" className="mb-4">

                        <Form.Label>Email</Form.Label>

                        <InputGroup>

                          <InputGroup.Text>

                            <FontAwesomeIcon icon={faEnvelope} />

                          </InputGroup.Text>

                          <Form.Control autoFocus name="email" type="email" placeholder="" className={errors.email ? 'error' : ''} value={formData.email} onChange={handleInputChange} />

                        </InputGroup>

                        <div className="error-message">{errors.email}</div>

                      </Form.Group>

                      <Button variant="primary" type="submit" className="btn btn-secondry" style={btnWidth}>

                        Reset Password

                      </Button><br /><br />

                      <Link to="/login">

                        <Button variant="primary" type="submit" className="btn btn-secondry" style={btnWidth}>

                          Return to Login Page

                        </Button></Link><br /><br />

                      <Link to={Routes.HelpMe.path}>
                        <Button variant="primary" type="button" className="btn btn-secondry" style={btnWidth}>
                          Help, I Can't log in
                        </Button>
                      </Link>

                    </Form>

                    <div className="bg-white p-0 pt-4">

                      <span class="mt-4 Copyright">Copyright @ 2023 SavveySavers</span>

                    </div>

                  </div>



                </div>

              </Col>

            </Row>

          </Container>

        </section>

      </main>

    </>

  );

};

