import React, { useState } from "react";
const About = () => {
	
	const headingStyle = {
	  marginTop: '0in',
	  marginRight: '0in',
	  marginBottom: '8.0pt',
	  marginLeft: '0in',
	  fontSize: '21px',
	  fontFamily: '"var(--bs-font-sans-serif)"',
	  color: '#4472C4',
	  color: 'black',
	  textAlign:'center'
	};

	const questionStyle = {
	  marginTop: '0in',
	  marginRight: '0in',
	  marginBottom: '8.0pt',
	  marginLeft: '0in',
	  fontSize: '19px',
	  fontFamily: '"var(--bs-font-sans-serif)"',
	  color: '#4472C4',
	  color: 'black',
	};

	const answerStyle = {
	  marginTop: '0in',
	  marginRight: '0in',
	  marginBottom: '8.0pt',
	  marginLeft: '0.2in',
	  fontSize: '16px',
	  lineHeight: '107%',
	  color: 'black',
	};
	
	const staticPages = {
	  padding: '100px',
	  paddingTop: '100px',
	};
	
	
    return (
        <>
            <div class="staticPages" style={staticPages}>
				<p style={questionStyle}>
				  <strong>About Us:</strong>
				</p>
				<p style={answerStyle}>
				 Welcome to Savvey Savers Network Limited where financial success is a collective journey. With a legacy of over a decade in connecting like-minded individuals offline, we have now embraced the digital era with a dedicated web platform to enhance the way we manage and grow our financial goals together.
				</p>
                <p style={questionStyle}>
				  <strong>Our Vision:</strong>
				</p>
				<p style={answerStyle}>
				 At Savvey Savers Network, we envision a community-driven approach to financial success and strongly believe that our “little drops together can make mighty savings.” We believe that by pooling together our resources, we can empower each other to achieve our dreams, whether it is saving for a mortgage deposit, funding education, investing in properties, or pursuing other financial aspirations.
				</p>
                 <p style={questionStyle}>
				  <strong>A Decade of Impact:</strong>
				</p>
				<p style={answerStyle}>
				 Our initiative has a rich history of over 10 years, fostering financial solidarity and propelling members towards their goals. What began as a passion project to help individuals access mortgage deposits faster, pay school fees, and invest in properties has evolved into a robust and trusted community.
				</p>
                 <p style={questionStyle}>
				  <strong>The Savvey Savers Network Difference:</strong>
				</p>
                <p style={questionStyle}>
				  <strong>1. Monthly Contributions with Purpose:</strong>
				</p>
				<p style={answerStyle}>
				Members contribute a predetermined amount each month towards a collective fund. This fund is then distributed to members in need interest free, creating a self-sustaining cycle of support.
				</p>
                 <p style={questionStyle}>
				  <strong>2. Interest-Free Financing:</strong>
				</p>
				<p style={answerStyle}>
				 We believe in the power of interest-free collaboration. Members receive funds for their financial needs and repay the community by continuing their monthly contributions, fostering a culture of mutual benefit.
				</p>
                
                 <p style={questionStyle}>
				  <strong>3. Beyond Banks and Loans:</strong>
				</p>
				<p style={answerStyle}>
				 Savvey Savers Network was birthed from the desire to provide an alternative to traditional banking systems and expensive loans. We champion the idea that together, we can achieve financial success without relying on conventional financial institutions.
				</p>
                 <p style={questionStyle}>
				  <strong>4. Credit-Rating Friendly:</strong>
				</p>
				<p style={answerStyle}>
				By bypassing traditional loans, our platform allows you to achieve your financial goals without negatively affecting your credit rating.
				</p>
                 <p style={questionStyle}>
				  <strong>How It Works:</strong>
				</p>
                <p style={questionStyle}>
				  <strong>1. Join the Community:</strong>
				</p>
				<p style={answerStyle}>
				Sign up and become a part of a community that shares your financial aspirations.
				</p>
                 <p style={questionStyle}>
				  <strong>2. Monthly Contributions:</strong>
				</p>
				<p style={answerStyle}>
				Contribute a fixed amount each month, knowing that your funds will support fellow members in achieving their financial goals.
				</p>
                 <p style={questionStyle}>
				  <strong>3. Access Funds When Needed:</strong>
				</p>
				<p style={answerStyle}>
				Savvey Savers Network allows you to plan ahead for your financial needs by allowing you choose a month to request funds from the pool and receive the support of the community.
				</p>
                 <p style={questionStyle}>
				  <strong>4. Interest-Free Repayment:</strong>
				</p>
				<p style={answerStyle}>
				Repay the funds interest-free by continuing your monthly contributions, helping others just as you were supported.
				</p>
                <p style={questionStyle}>
				  <strong>Join Us on the Journey:</strong>
				</p>
				<p style={answerStyle}>
				Whether you are looking to secure a mortgage, fund education, or make strategic investments, Savvey Savers Network is here to transform individual dreams into collective success. Join us today and experience the power of community-driven financial growth.
				</p>
				<p style={questionStyle}>
				  <strong>EMPOWER. SUPPORT. ACHIEVE.</strong>
				</p>
			</div>

        </>
    )
}
export default About;