import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, Dropdown, InputGroup } from '@themesberg/react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';

import Preloader from "../components/Preloader";
import Loader from "../components/Loader";

import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import axios from 'axios';
import swal from 'sweetalert';


export default () => {
	const [birthday, setBirthday] = useState("");	
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	
	// Create Users
	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',   
		email: '',
		phone_number: '',
		address_1: '',
		address_2: '',   
		city: '',
		post_code: '',
		country: '',
		terms_conditions: false,
	});

	const [errors, setErrors] = useState({
		first_name: '',
		last_name: '',  
		email: '',
		phone_number: '',
		address_1: '',
		address_2: '',   
		city: '',
		post_code: '',
		country: '',
		terms_conditions: '',
	});
  
	const [invalid, setInvalid] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
		  ...formData,
		  [name]: value,
		});
	};
	const history = useHistory();
	const storedUser = localStorage.getItem('user');
	if (!storedUser) {
	  // Redirect to the login URL
	  history.push('/'); // Replace '/login' with the actual URL of your login page
	  return false;
	}
	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;
	const user_id = userObject.user_id;
  
	const fetchData = async page => {

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/users/'+user_id;  
			
		
		const formData = new FormData();

		setLoading(true);
						 
		axios.post(apiBaseURL, formData, {
		  headers: {
			Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
		  }
		})
		.then(({ data }) => {
			if(data.status==="success"){
			  setData(data.data.data);

			  setFormData({
			  ...formData,
			  ["first_name"]: data.data.data[0].first_name,
			  ["last_name"]: data.data.data[0].last_name,
			  ["email"]: data.data.data[0].email,
			  ["phone_number"]: data.data.data[0].phone_number,
			  ["address_1"]: data.data.data[0].address_1,
			  ["address_2"]: data.data.data[0].address_2,
			  ["city"]: data.data.data[0].city,
			  ["post_code"]: data.data.data[0].post_code,
			  ["country"]: data.data.data[0].country,
			  ["terms_conditions"]: data.data.data[0].terms_conditions,
			  });
			  
			}
			setLoading(false);
		}).catch(error => {
			setLoading(false);
		});
    };
	
    useEffect(()=>{
	  fetchData();
	},[]);
	
	
	// Update Url Info
	const handleSubmit = (e) => {  
		e.preventDefault();
		
		let valid = true;
		const newErrors = {
		  first_name: '',
		  last_name: '',
		  email: '',
		  phone_number: '',
		  address_1: '',
		  address_2: '',   
		  city: '',
		  post_code: '',
		  country: '',
		  terms_conditions: '',
		};
		
		
		if(formData.first_name === "" || formData.first_name === null){
			newErrors.first_name = 'First Name field is required';
			valid = false;
		}
		
		if(formData.last_name === "" || formData.last_name === null){
			newErrors.last_name = 'Last Name field is required';
			valid = false;
		}
		
		if(formData.email === "" || formData.email === null){
			newErrors.email = 'Email field is required';
			valid = false;
		}

		if (!formData.email || !formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
		  newErrors.email = 'Please enter a valid email address';
		  valid = false;
		}
		
		if(formData.phone_number === "" || formData.phone_number === null){
			newErrors.phone_number = 'Phone Number field is required';
			valid = false;
		}

		if (!formData.phone_number || formData.phone_number.length < 10) {
		  newErrors.phone_number = 'Enter valid Phone Number';
		  valid = false;
		}
		
		if(formData.post_code === "" || formData.post_code === null){
			newErrors.post_code = 'Post Code field is required';
			valid = false;
		}
		
		if(formData.country === "" || formData.country === null){
			newErrors.country = 'Country field is required';
			valid = false;
		}

		setErrors(newErrors);

		if (valid) {
			setLoading(true);
			console.log("formData",formData);

			const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/users/updateProfile/'+user_id; 
				
			axios.post(apiBaseURL, formData, {
			})
			.then(({ data }) => {
				if(data.status==="success"){
					setLoading(false);
					let newData = JSON.parse(localStorage.getItem('user'));
					newData.user_name = formData.first_name + ' ' + formData.last_name;
					console.log("newData", newData);
					localStorage.setItem('user', JSON.stringify(newData));
					swal("Success!", "Your profile has been updated successfully!", "success").then(() => {
						window.location.reload();
					});
				}
			}).catch(error => {
			});

		}
	};
	
	
	if (loading) {
		//return <div>Loading...</div>;
	}
  
  return (
    <>
		{loading && <Preloader show={true} />}
       <Row className="mt-5">
        <Col xs={12} xl={12}>
			<Card border="light" className="bg-white shadow-sm mb-4">
			  <Card.Body>
				<h5 className="mb-4">My Details</h5>
				
				<Form onSubmit={handleSubmit}>
				  <Row>
					<Col md={6} className="mb-3">
					  <Form.Group id="first_name">
						<Form.Label>First Name <span class="error-message">*</span></Form.Label>
						<Form.Control type="text" name="first_name" placeholder="First Name" className={errors.first_name ? "is-invalid" : ""} onChange={handleInputChange} value={formData.first_name} />
					  </Form.Group>
					</Col>
					<Col md={6} className="mb-3">
					  <Form.Group id="last_name">
						<Form.Label>Last Name <span class="error-message">*</span></Form.Label>
						<Form.Control type="text" name="last_name" placeholder="Last Name" className={errors.last_name ? "is-invalid" : ""} onChange={handleInputChange} value={formData.last_name}/>
					  </Form.Group>
					</Col>
				  </Row>
				  
				  <Row>
					<Col md={6} className="mb-3">
					  <Form.Group id="phone">
						<Form.Label>Phone Number <span class="error-message">*</span></Form.Label>
						<Form.Control type="number" name="phone_number" placeholder="Phone Number" className={errors.phone_number ? "is-invalid" : ""} onChange={handleInputChange} value={formData.phone_number}/>
					  </Form.Group>
					  <div className="error-message">{errors.phone_number}</div>
					</Col>
					<Col md={6} className="mb-3">
					  <Form.Group id="email">
						<Form.Label>Email</Form.Label>
						<Form.Control type="email" name="email" placeholder="Email" className={errors.email ? "is-invalid" : ""} onChange={handleInputChange} value={formData.email} readOnly />
					  </Form.Group>
					  <div className="error-message">{errors.email}</div>
					</Col>
				  </Row>
				  
				  <Row>
					<Col md={12} className="mb-3">
					  <Form.Group id="address_1">
						<Form.Label>Address Line 1</Form.Label>
						<Form.Control type="text" name="address_1" placeholder="Address Line 1" value={formData.address_1} onChange={handleInputChange} />
					  </Form.Group>
					  <div className="error-message">{errors.address_1}</div>
					</Col>
				  </Row>
				  
				  <Row>
					<Col md={12} className="mb-3">
					  <Form.Group id="address_2">
						<Form.Label>Address Line 2</Form.Label>
						<Form.Control type="text" name="address_2" placeholder="Address Line 2" value={formData.address_2} onChange={handleInputChange} />
					  </Form.Group>
					  <div className="error-message">{errors.address_2}</div>
					</Col>
				  </Row>
				  
				  <Row>
					<Col md={6} className="mb-3">
					  <Form.Group id="city">
						<Form.Label>City</Form.Label>
						<Form.Control type="text" name="city" placeholder="City" value={formData.city} onChange={handleInputChange} />
					  </Form.Group>
					  <div className="error-message">{errors.city}</div>
					</Col>
					<Col md={6} className="mb-3">
					  <Form.Group id="post_code">
						<Form.Label>Post Code <span class="error-message">*</span></Form.Label>
						<Form.Control type="text" name="post_code" placeholder="Post Code" className={errors.post_code ? "is-invalid" : ""} value={formData.post_code} onChange={handleInputChange} />
					  </Form.Group>
					  <div className="error-message">{errors.post_code}</div>
					</Col>
				  </Row>
				  
				  <Row>
					<Col md={6} className="mb-3">
					  <Form.Group id="country">
						<Form.Label>Country</Form.Label>
						<Form.Control type="text" name="country" placeholder="Country" className={errors.country ? "is-invalid" : ""} value={formData.country} onChange={handleInputChange} readOnly/>
					  </Form.Group>
					  <div className="error-message">{errors.country}</div>
					</Col>
				  </Row>

				  <div className="mt-3 center">
					<Button variant="primary" type="submit">Save</Button>
				  </div>
				</Form>
				
			  </Card.Body>
			</Card>
        </Col>
      </Row>
    </>
  );
};
