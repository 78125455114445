import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, Dropdown, ButtonGroup, Breadcrumb, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import { Link, useHistory } from 'react-router-dom';
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { EmailTemplateTable } from "../components/EmailTemplateTable";
import Preloader from "../components/Preloader";
import Loader from "../components/Loader";

export default () => {
	
	const [birthday, setBirthday] = useState("");	
	const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => setShowDefault(false);
	const [search, setSearch] = useState("");
	const [data, setData] = useState([]);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	
	const storedUser = localStorage.getItem('user');
	if (!storedUser) {
	  // Redirect to the login URL
	  history.push('/'); // Replace '/login' with the actual URL of your login page
	  return false;
	}
	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;
	
	// Create Users
	const [formData, setFormData] = useState({
		question_name: ''
	});

	const [errors, setErrors] = useState({
		question_name: ''
	});
  
	const [invalid, setInvalid] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
		  ...formData,
		  [name]: value,
		});
	};
  
	const handleSubmit = (e) => {
		e.preventDefault();
		// Validate email and password
		let valid = true;
		const newErrors = {
			question_name: ''
		};

		
		if(formData.question_name === ""){
			newErrors.question_name = 'Question Name field is required';
			valid = false;
		}
		
		setErrors(newErrors);

		if (valid) {
			setLoading(true);
			const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/security/create'; 
			
			axios.post(apiBaseURL, formData, {
			  headers: {
				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
			  }
			})
			.then(({ data }) => {
				if(data.status==="success"){
					setShowDefault(false);
				}
				setLoading(false);
				window.location.reload();
			}).catch(error => {
				setLoading(false);
			});
			
		}
	};
	// End Create Users

	const handleInputChangeSearch = (e) => {
		const { name, value } = e.target;
		setSearch(value);
	};
	
	if (loading) {
		//return <Preloader show={loading ? true : false} />
	}

	
		
	
  return (
    <>
	  {loading && <Loader/>}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Email Template</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Email Templates</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" name="searchKeyword" onChange={handleInputChangeSearch}/>
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={2} className="ps-md-0 text-end">
          </Col>
        </Row>
      </div>

      <EmailTemplateTable data={search}/>
	  
	  
	    <React.Fragment>
		  <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
			<Modal.Header>
			  <Modal.Title className="h6">Add Questions</Modal.Title>
			  <Button variant="close" aria-label="Close" onClick={handleClose} />
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xs={12} xl={12}>
						<Card border="light" className="bg-white mb-4">
						  <Card.Body>
							{/* <h5 className="mb-4">Add Question</h5> */}
							<Form onSubmit={handleSubmit}>
							  <Row>
								<Col md={12} className="mb-3">
								  <Form.Group id="QuestionName">
									<Form.Label>Question Name</Form.Label>
									<Form.Control type="text" name="question_name" placeholder="Enter Question name" onChange={handleInputChange} />
								  </Form.Group>
								  <div className="error-message">{errors.question_name}</div>
								</Col>
							  </Row>
							  
							  <div className="mt-3 center">
								<Button variant="primary" type="submit">Save</Button>
							  </div>
							</Form>
						  </Card.Body>
						</Card>
					</Col>
				  </Row>
			</Modal.Body>
		  </Modal>
		</React.Fragment>
	  
    </>
  );
};
