
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';
import loadingImg from "../assets/img/loading.gif";

export default (props) => {

  const { show } = props;

  return (
    <div>
       <img src={loadingImg} alt="Loading..." class="loadingCss"/>
    </div>
  );
};
