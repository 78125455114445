import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, Dropdown, ButtonGroup, Breadcrumb, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import { Link, useHistory } from 'react-router-dom';
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { NotificationTable } from "../components/NotificationTable";
import Preloader from "../components/Preloader";
import Loader from "../components/Loader";

export default () => {
	
	const [birthday, setBirthday] = useState("");	
	const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => setShowDefault(false);
	const [search, setSearch] = useState("");
	const [data, setData] = useState([]);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	
	const storedUser = localStorage.getItem('user');
	if (!storedUser) {
	  // Redirect to the login URL
	  history.push('/'); // Replace '/login' with the actual URL of your login page
	  return false;
	}
	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;

	const handleInputChangeSearch = (e) => {
		const { name, value } = e.target;
		setSearch(value);
	};
	
	if (loading) {
		//return <Preloader show={loading ? true : false} />
	}

	
		
	
  return (
    <>
	  {loading && <Loader/>}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Notification</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Notification</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" name="searchKeyword" onChange={handleInputChangeSearch}/>
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={2} className="ps-md-0 text-end">
          </Col>
        </Row>
      </div>

      <NotificationTable data={search}/>
	  
    </>
  );
};
