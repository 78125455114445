

import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEnvelope, faUnlockAlt, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Alert, Card, Button, FormCheck, Container, InputGroup, Image } from '@themesberg/react-bootstrap';



import BgImage from "../assets/img/illustrations/login_img.jpg";

import logoImage from "../assets/img/illustrations/logo.png";

import remove_icon from "../assets/img/illustrations/remove_icon.svg";

import axios from 'axios';

import { useHistory, useParams } from 'react-router-dom';

import Loader from "../components/Loader";







export default () => {

	

   const [loading, setLoading] = useState(true);

   const [pageLoad, setPageLoad] = useState(true); 	

   const { register_token } = useParams();
   
   
   const [showPassword, setShowPassword] = useState(false);
  
	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
		if(!showPassword){
			const timer = setTimeout(() => {
			  setShowPassword(showPassword);
			}, 5000);
		}
	};

	

  const [formData, setFormData] = useState({

	username: '',

	password: '',

	register_token:register_token,

  });



  const [errors, setErrors] = useState({

	username: '',

	password: '',

  });

  

  const [invalid, setInvalid] = useState(false);

  const [invaliderror, setInvalidError] = useState("");

  const [isChecked, setIsChecked] = useState(false);

  
  const [passwordValid, setIspasswordValid] = useState(true);


  const handleInputChange = (e) => {

    const { name, value } = e.target;

    setFormData({

      ...formData,

      [name]: value,

    });

  };

  const history = useHistory();

  

  

	const [checkedQuestions, setCheckedQuestions] = useState({});

	const [answers, setAnswers] = useState([]);



	const handleCheckboxChange = (questionIndex,question_id) => {

		setCheckedQuestions((prevCheckedQuestions) => {

		  // If the checkbox is not already selected, and the number of selected checkboxes is less than 2

		  if (!prevCheckedQuestions[questionIndex] && Object.values(prevCheckedQuestions).filter(Boolean).length >= 2) {

			return prevCheckedQuestions; // Do not update the state

		  }



		  return {

			...prevCheckedQuestions,

			[questionIndex]: !prevCheckedQuestions[questionIndex],

		  };

		});

	};

	

	const handleAnswerChange = (questionIndex, answer, question_id) => {

		console.log("question_id",question_id);

		setAnswers((prevAnswers) => {

		  const updatedAnswers = [...prevAnswers];

		  updatedAnswers[questionIndex] = {"question_id":question_id,"answer":answer};

		  return updatedAnswers;

		});

	};

	

   const totalSelected = Object.values(checkedQuestions).filter(Boolean).length;



  const handleSubmit = (e) => {  

    e.preventDefault();

    

    let valid = true;
	let passwordvalid = true;

    const newErrors = {
	  username: '',
	  password: '',
    };

	
	if(formData.username === ""){
		newErrors.username = 'Username field is required.';
		valid = false;
	}

	
	if(formData.password === ""){
		newErrors.password = 'Password field is required.';
		valid = false;
		setIspasswordValid(true);
		passwordvalid = false;
	}

	
	if (!formData.password || formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long.';
      valid = false;
	  setIspasswordValid(true);
	  passwordvalid = false;
    }


    if (formData.password!= "") {

		let validate_password = validatePassword(formData.password);

		
		if(validate_password == false){
			newErrors.password = 'Password should have a minimum of 8 characters, including one upper and one lower character, one numbers and one special £$#!*& Character';
			valid = false;
			setIspasswordValid(true);
		    passwordvalid = false;
		} else {
			newErrors.password = "";
			setIspasswordValid(false);
		    passwordvalid = true;
		}
    }

    setErrors(newErrors);

	
	const selectedQuestionsWithAnswers = Object.keys(checkedQuestions)

		.filter((questionIndex) => checkedQuestions[questionIndex])

		.map((questionIndex) => answers[questionIndex]);



	const isInvalid = selectedQuestionsWithAnswers.some(
		(answer) => !answer || !answer.answer
	);

	

	if(totalSelected > 1){
		setInvalid(false);
	} else {
		setInvalid(true);
		valid = false;
		setInvalidError("Please Select 2 Security Question to complete this step");
		return;
	}

	console.log("isInvalid",isInvalid);

	if(isInvalid){
		setInvalidError("Please Fill Answer for both the questions");
		setInvalid(true);
		valid = false;
		return;
	} else {
		setInvalid(false);
	}


    if (valid) {
		setLoading(true);
		console.log("answers",answers);
		

		const filteredData = answers.filter(item => item !== undefined && item.answer !== undefined);
		console.log("filteredData",filteredData);
		const nonEmptyAnswers = filteredData.filter(answer => Object.keys(answer).length > 0);
		console.log("nonEmptyAnswers",nonEmptyAnswers);

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/users/setupPassword'; 

		const additionalData = {
		  answers: nonEmptyAnswers
		};		

		const mergedFormData = { ...formData, ...additionalData };


		axios.post(apiBaseURL, mergedFormData, {

		}).then(({ data }) => {

			if(data.status==="success"){
				setLoading(false);
				localStorage.setItem('user', JSON.stringify(data.content));
				history.push(`/saving_commitments`);
			}

		}).catch(error => {

		});

    }

  };

  

  

	const validatePassword = (password) => {

		//const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[£$#!@*&]).{8,}$/;
		const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[£$#!*&]).{8,}$/;

		return passwordRegex.test(password);

	};





	const [question, setQuestion] = useState(true);

	useEffect(()=>{

	 

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/securtyQuestion';  

						 

		const formData1 = new FormData();

						 

		axios.get(apiBaseURL, formData1, {

		})

		.then(({ data }) => {

			if(data.status==="success"){

			  setQuestion(data.data);

			}

			

			const apiBaseURL1 = process.env.REACT_APP_API_BASE_URL+'/users/getDetails';  

			const formData2 = new FormData();

			formData2.append('register_token', register_token);	

			

			axios.post(apiBaseURL1, formData2, {})

			  .then(response => {

				console.log("Response from server:", response); // Log the entire response object



				const { data1 } = response.data; // Extract data1 from the response



				if (response.data.status === "success") {

				  setLoading(false);

				  setPageLoad(false);

				  setFormData({

					...formData,

					["username"]: response.data.data.email,

				  });

				}

			  })

			  .catch(error => {

				setLoading(false);

				console.error("Error:", error); // Log any error that occurred during the request

			  });



			

		}).catch(error => {

			setLoading(false);

			setPageLoad(false);

		});

	

	},[]);



  

   const divStyle = {

    height: '100%', // Note: Make sure to enclose 100% in quotes

    

    left: 0,

    top: 0,

    overflow: 'hidden', // Note: Make sure to enclose 'hidden' in quotes

    position: 'fixed'   // Note: Make sure to enclose 'fixed' in quotes

  };

  

  if (loading && pageLoad) {

	return <div>Loading...</div>;

  }

  

  

  return (

	<>

	{loading && !pageLoad && <Loader/>}

    <main>

      <section className="d-flex align-items-center p-0">

        <Container fluid className="p-0">

          <Row className="m-0 user-area-section setup-password-page" style={ divStyle }>

            <Col md={8} className="p-0" > 

              <div className="form-bg-image">

                <img src={BgImage} alt="Background" className="w-100 h-100" />

              </div>

            </Col>

            <Col md={4} className="bg-white">

              <div className="bg-white p-0 p-lg-4 mg-b">

                <div className="text-center text-md-center mb-4 mt-md-0">

                  <img src={logoImage} alt="Background" style={{ height: '100px' }} />

                  <h5 className="mb-0">Setup Password</h5>

                </div>

                <Form className="mt-4" onSubmit={handleSubmit}>

				

				   <Row>

					<Col md={12} className="mb-3">

					  <Form.Group id="username">

					    <Form.Label>Username</Form.Label>

						<Form.Control type="text" name="username" placeholder="Username" onChange={handleInputChange} value={formData.username} autoComplete="off" readOnly/>

					  </Form.Group>

					  <div className="error-message">{errors.username}</div>

					</Col>

					<Col md={12} className="mb-3">

					  <Form.Group id="password">

					    <Form.Label>Password</Form.Label>

						<InputGroup>
							<Form.Control type={showPassword ? 'text' : 'password'} name="password" placeholder="Password" onChange={handleInputChange} value={formData.password} autoComplete="off"/>
							
							<Button variant="light" onClick={togglePasswordVisibility}>
								{showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
							</Button>
						</InputGroup>
					  </Form.Group>

					  {/* <div className="error-message">{errors.password}</div> */}
					  {passwordValid && (
						  <div className="success-message">
							<p>Password should be: </p>
							<p><Image src={remove_icon} width={20} height={20}/> 8 or more characters</p>
							<p><Image src={remove_icon} width={20} height={20}/> At least one upper and one lowercase letter</p>
							<p><Image src={remove_icon} width={20} height={20}/> At least one number</p>
							<p><Image src={remove_icon} width={20} height={20}/> At least one of the following special characters <br/><br/> <span> [ £ $ # ! * & ] </span></p>
						  </div>
						)}

					</Col>

				  </Row>

				  

				  <Row><Col md={12} className="mb-3"> <h4><b> Password Security Question (Select Any Two)</b></h4></Col></Row>

				  

					{invalid && 

					<Alert variant="danger">

						{invaliderror}

					</Alert>

					}

				  

				  {question.map((questions, index) => (

					  <Row>

						<Col md={12} className="mb-3">

						  <div> <FormCheck.Input id={`checkbox-${index}`} name={`checkbox-${index}`} onChange={() => handleCheckboxChange(index,questions.id)} className="me-2"/> {questions.question_name}</div>

						</Col>

						{checkedQuestions[index] && (

						<Col md={12} className="mb-3">

							<Form.Group id="firstName">

								<Form.Control type="text" name={`questions_${index + 1}`} placeholder="Answer" onChange={(e) => handleAnswerChange(index, e.target.value, questions.id)}/>

							</Form.Group>

						</Col>

						)}

					  </Row>

				  ))}

				  

                  <Button variant="primary" type="submit" className="btn btn-secondry">

                    Setup password

                  </Button>

                </Form>

              </div>

			  <div className="bg-white p-0 p-lg-4 center">

				<span class="mt-4 Copyright">Copyright @ 2023 SavveySavers</span>

			  </div>

            </Col>

          </Row>

        </Container>

      </section>      

    </main>

	</>

  );

};

