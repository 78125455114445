import React, { useState, useEffect, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt, faRuler, faReceipt, faFileInvoice, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup, Modal, Row, Col, Form } from '@themesberg/react-bootstrap';

import { Link } from 'react-router-dom';

import Preloader from "../components/Preloader";

import Loader from "../components/Loader";



import { Routes } from "../routes";

import transactions from "../data/transactions";

import axios from 'axios';

import Swal from 'sweetalert2';



const ValueChange = ({ value, suffix }) => {

	const valueIcon = value < 0 ? faAngleDown : faAngleUp;

	const valueTxtColor = value < 0 ? "text-danger" : "text-success";



	return (

		value ? <span className={valueTxtColor}>

			<FontAwesomeIcon icon={valueIcon} />

			<span className="fw-bold ms-1">

				{Math.abs(value)}{suffix}

			</span>

		</span> : "--"

	);

};







export const SavingCommitmentTable = (searchKey) => {



	const storedUser = localStorage.getItem('user');

	const userObject = JSON.parse(storedUser);

	const accessToken = userObject.access_token;

	const user_id = userObject.user_id;



	const [showDefault, setShowDefault] = useState(false);

	const handleClose = () => setShowDefault(false);



	const [data, setData] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);

	const [startPage, setStartPage] = useState(1);

	const [endPage, setEndPage] = useState(1);

	const [totalRecord, setTotalRecord] = useState(1);

	const [perPage, setPerPage] = useState(1);

	const [totalPages, setTotalPages] = useState(1);

	const [search, setSearch] = useState("");
	
	const [currentSort, setCurrentSort] = useState("id");
	
	const [currentDirection, setCurrentDirection] = useState("desc");

	const [loading, setLoading] = useState(true);

	const [savingCommitmentId, setSavingCommitmentId] = useState("");



	const [commitmentmonth, setCommitmentMonth] = useState([]);

	const [commitmentgoal, setCommitmentGoal] = useState([]);

	const [severs, setSaversList] = useState([]);

	const [amountDatas, setAmount] = useState([]);



	const [showCommitmentDefault, setShowCommitmentDefault] = useState(false);

	const [commitmentData, setCommitmentData] = useState([]);

	const handleCloseCommitment = () => setShowCommitmentDefault(false);





	{/*Sorting*/ }

	const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });



	const handleSort = (key) => {

		let direction = 'asc';

		if (sortConfig.key === key && sortConfig.direction === 'asc') {

			direction = 'desc';

		}
		
		setCurrentSort(key);
		
		setCurrentDirection(direction);

		setSortConfig({ key, direction });



		console.log("key", key);

		console.log("direction", direction);



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/saving_commitment/' + user_id;



		const formData = new FormData();

		formData.append('page', 1);

		formData.append('search', search);

		formData.append('sortBy', key);

		formData.append('orderBy', direction);



		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorizations: accessToken // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};



	const getSortIcon = (columnKey) => {

		if (sortConfig.key === columnKey) {

			return sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;

		}

		return <FontAwesomeIcon icon={faSort} />;

	};

	{/*End Sorting*/ }





	const fetchData = async page => {



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/saving_commitment/' + user_id;



		const formData = new FormData();

		if (typeof page !== 'undefined') {
		  formData.append('page', page);
		} else {
		  formData.append('page', currentPage);
		}

		formData.append('search', search);

		formData.append('sortBy', currentSort);
		
		formData.append('orderBy', currentDirection);

		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorizations: accessToken // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};







	useEffect(() => {

		setSearch(searchKey?.data);

	}, [searchKey?.data]);

	useEffect(() => {
		fetchData(currentPage);
	}, [search]);





	const handlePageChange = page => {

		setCurrentPage(page);

	};



	const onPageChange = (pageNo) => {

		setCurrentPage(pageNo);

		fetchData(pageNo);

	};



	const onViewData = (id, amount, saving_goal, collection_month, serialNumber, savers_name, formattedEndDate) => {



		let datas = { "id": id, "amount": amount, "saving_goal": saving_goal, "collection_month": collection_month, "serialNumber": serialNumber, "savers_name": savers_name, "formattedEndDate": formattedEndDate }

		console.log("datas", datas);

		setCommitmentData(datas);



		setShowCommitmentDefault(true);

	};







	{/*......................................Edit.................................*/ }

	const [formData, setFormData] = useState({

		amount: '',

		saving_goal: '',

		commitment_details: '',

		collection_month: '',

		collection_year: '',

		user_id: user_id

	});



	const [errors, setErrors] = useState({

		amount: '',

		saving_goal: '',

		commitment_details: '',

		collection_month: '',

		collection_year: '',

	});



	const [invalid, setInvalid] = useState(false);



	const handleInputChange = (e) => {

		const { name, value } = e.target;

		setFormData({

			...formData,

			[name]: value,

		});

	};



	useEffect(() => {

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/commitment_month';

		axios.get(apiBaseURL)

			.then((response) => {

				// Assuming your API response is an array of objects with 'value' and 'label' properties

				setCommitmentMonth(response.data.data.commitment_months)

				setCommitmentGoal(response.data.data.commitment_goals)

				setSaversList(response.data.data.savers_list)

				setAmount(response.data.data.commitment_amount)

			})

			.catch((error) => {

				console.error('Error fetching data:', error);

			});

	}, []);



	const onEditData = (props, collection_month, endYear) => {



		setFormData({

			...formData,

			amount: props.amount,

			saving_goal: props.saving_commitment_list_id,

			collection_month: collection_month,

			collection_year: endYear,

			commitment_details: props.commitment_details

		});



		setSavingCommitmentId(props.id)

		setShowDefault(true);

	};



	const handleSubmit = (e) => {

		e.preventDefault();

		// Validate email and password

		let valid = true;

		const newErrors = {

			amount: '',

			saving_goal: '',

			commitment_details: '',

			collection_month: '',

			collection_year: '',

		};



		if (formData.amount === "") {

			newErrors.amount = 'Saving commitment amount field is required';

			valid = false;

		}

		if (formData.saving_goal === "") {

			newErrors.saving_goal = 'Saving Goal field is required';

			valid = false;

		}



		if (formData.saving_goal == "9" && formData.commitment_details === "") {

			newErrors.commitment_details = 'Commitment Details is required';

			valid = false;

		}



		if (formData.collection_month === "") {

			newErrors.collection_month = 'Please Select Collection Month';

			valid = false;

		}

		if (formData.collection_year === "") {

			newErrors.collection_year = 'Please Select Collection Year';

			valid = false;

		}

		setErrors(newErrors);



		if (valid) {

			console.log("savingCommitmentId", savingCommitmentId);

			console.log("formData", formData);



			setLoading(true);

			const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/update_saving_commitment/' + savingCommitmentId;



			axios.post(apiBaseURL, formData, {

				headers: {

					Authorizations: accessToken // Include the access token in the Authorization header

				}

			})

				.then(({ data }) => {

					if (data.status === "success") {

						setShowDefault(false);

						setLoading(false);

					}

					setLoading(false);

					//window.location.reload();
					
					fetchData();

				}).catch(error => {

					setLoading(false);

				});



		}

	};

	{/*......................................Edit.................................*/ }





	{/*...........................Approve Monthly Payment....................*/ }

	const approveMonthlyPayment = (props) => {

		console.log("props", props)

		const formData1 = new FormData();

		formData1.append('requested_id', props.id);



		Swal.fire({

			title: "Monthly Payment Receipt",

			text: "Are you sure you want to confirm receipt of this month's payment ?",

			icon: 'warning',

			showCancelButton: true,

			confirmButtonText: 'Confirm',

			cancelButtonText: 'Cancel',

			confirmButtonColor: '#262B40',

		}).then((result) => {

			if (result.isConfirmed) {

				const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/set_payment';

				axios.post(apiBaseURL, formData1, {

					headers: {

						Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

					}

				})

					.then(({ data }) => {

						if (data.status === "success") {

							setShowDefault(false);

							Swal.fire("You have successfully confirmed receipt of this month's payment.", '', 'success').then(() => {

								window.location.reload();

							})

						}

						//setLoading(false);

					}).catch(error => {

						//setLoading(false);

					});

			}

		});



	};

	{/*.....................approve end payment......................*/ }







	{/*...........................Complete Monthly Payment....................*/ }

	const completeMonthlyPayment = (props) => {

		console.log("props", props)

		const formData1 = new FormData();

		formData1.append('requested_id', props.id);



		Swal.fire({

			title: "Savings Harvest Release",

			text: "Are you sure you want to release this user's savings harvest?",

			icon: 'warning',

			showCancelButton: true,

			confirmButtonText: 'Confirm',

			cancelButtonText: 'Cancel',

			confirmButtonColor: '#262B40',

		}).then((result) => {

			if (result.isConfirmed) {

				const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/set_harvest_release';

				axios.post(apiBaseURL, formData1, {

					headers: {

						Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

					}

				})

					.then(({ data }) => {

						if (data.status === "success") {

							setShowDefault(false);

							Swal.fire('Savings harvest release notification has been sent to the member.', '', 'success').then(() => {

								window.location.reload();

							})

						}

						//setLoading(false);

					}).catch(error => {

						//setLoading(false);

					});

			}

		});



	};

	{/*.....................Complete end payment......................*/ }







	const getCurrentYear = () => {

		const currentYear = new Date().getFullYear();

		const years = [];



		for (let i = 0; i <= 10; i++) {

			years.push(currentYear + i);

		}



		return years;

	};



	const yearsList = getCurrentYear();





	if (loading) {

		//return <div>Loading...</div>;

	}



	const items = [];

	for (let i = 0; i < totalPages; i++) {

		items.push(i + 1);

	}





	const totalUsers = data.length;



	const srStart = (currentPage - 1) * perPage;



	const modifiedData = data.map((t, index) => ({

		...t,

		serialNumber: srStart + index + 1 // Assuming the serial number starts from 1

	}));



	const prevPage = currentPage > 1 ? currentPage - 1 : 1;

	const nextPage = currentPage < totalPages ? currentPage + 1 : totalPages;


	const cancelsavingcommitment = (data) => {
		Swal.fire({

			title: "Cancel Saving Commitment",

			text: "Are you sure, do you want to Cancel Saving Commitment",

			icon: 'warning',

			showCancelButton: true,

			confirmButtonText: 'Confirm',

			cancelButtonText: 'Cancel',

			confirmButtonColor: '#262B40',

		}).then((result) => {

			if (result.isConfirmed) {

				const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/cancel-savingCommitment';

				axios.post(apiBaseURL, data, {

					headers: {

						Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

					}

				})

					.then(({ data }) => {

						if (data.status === "success") {

							setShowDefault(false);

							Swal.fire('Saving commitment canceled', '', 'success').then(() => {

								window.location.reload();

							})

						}


					}).catch(error => {


					});

			}

		});
	}





	const TableRow = (props) => {

		const { id, amount, saving_goal, commitment_details, collection_month, serialNumber, savers_name, created_at, status } = props;



		// Convert created_at to a Date object

		const createdAtDate = new Date(created_at.replace(/\s/, 'T'));

		const startDate = new Date(created_at.replace(/\s/, 'T'));

		let endMonth = startDate.getMonth();

		let endYear = startDate.getFullYear();

		const monthNames = [

			'January', 'February', 'March', 'April', 'May', 'June',

			'July', 'August', 'September', 'October', 'November', 'December'

		];



		// Format created_at as DD-MM-YYYY (for example)

		const options = { year: 'numeric', month: 'long' };

		//const formattedCreatedAt = createdAtDate.toLocaleDateString(undefined, options);

		const formattedCreatedAt = collection_month + ` ${endYear}`;



		function YourComponent({ created_at }) {

			const formattedCreatedAt = useMemo(() => {

				const createdAtDate = new Date(created_at);

				const options = { year: 'numeric', month: 'long' };

				return createdAtDate.toLocaleDateString(undefined, options);

			}, [created_at]);



			return <div>{formattedCreatedAt}</div>;

		}



		// Get end date by adding one year to created_at

		const endDate = new Date(createdAtDate);

		endDate.setFullYear(endDate.getFullYear() + 1);



		// Format end date as DD-MM-YYYY (for example)

		//const formattedEndDate = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;

		const formattedEndDate = `December ${endYear}`;





		const paymentcreatedAtDate = new Date();

		const years = paymentcreatedAtDate.getFullYear();

		const months = paymentcreatedAtDate.getMonth() + 1;

		const formattedMonth = months < 10 ? `0${months}` : `${months}`;

		const payment_date = `${years}-${formattedMonth}`;



		return (

			<tr>

				<td>

					<span className="fw-normal text-primary" style={{ cursor: 'pointer' }} onClick={() => onViewData(id, amount, saving_goal, collection_month, serialNumber, savers_name, formattedEndDate)}>

						SC-00{id}

					</span>

				</td>

				<td>

					<span className="fw-normal">

						{savers_name}

					</span>

				</td>

				<td>

					<span className="fw-normal">

						{"£" + amount}

					</span>

				</td>

				<td>

					<span className="fw-normal">

						{formattedCreatedAt}

					</span>

				</td>

				<td>

					<span className="fw-normal">

						{formattedEndDate}

					</span>

				</td>

				<td>
					{status ? <span className="fw-normal">
						<button type="button" className="m-1 btn btn-primary btn-sm">Active</button>
					</span> : <span className="fw-normal">
						<button type="button" className="m-1 btn btn-primary btn-sm">Cancelled</button>
					</span>}
				</td>

				<td>

					<Dropdown as={ButtonGroup}>
						<Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
							<span className="icon icon-sm">
								<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
							</span>
						</Dropdown.Toggle>
						<Dropdown.Menu>

							{/* 
							<Dropdown.Item className="text-primary">
								<FontAwesomeIcon icon={faEye} className="me-2"  />View
							</Dropdown.Item> */}


							<Dropdown.Item className="text-primary" onClick={() => onEditData(props, collection_month, endYear)}>
								<FontAwesomeIcon icon={faEdit} className="me-2" />Edit
							</Dropdown.Item>



							<Dropdown.Item className="text-primary" onClick={() => String(payment_date) === String(props.last_payment) ? '' : approveMonthlyPayment(props)} >
								{String(payment_date) === String(props.last_payment) ? (

									<FontAwesomeIcon icon={faReceipt} className="me-2" title="Payment Done" style={{ color: 'green' }} />

								) : (

									<FontAwesomeIcon icon={faReceipt} className="me-2" title="Confirm Payment Receipt" />

								)}
								{String(payment_date) === String(props.last_payment) ? (

									"  Payment Done"

								) : (

									"  Confirm Payment Receipt"

								)}
							</Dropdown.Item>
							<Dropdown.Item className="text-primary" onClick={() => props.is_payment_released === "1" ? '' : completeMonthlyPayment(props)}>
								{props.is_payment_released === "1" ? (

									<FontAwesomeIcon icon={faFileInvoice} className="me-2" style={{ color: 'green' }} title="Harvest Completed" />

								) : (

									<FontAwesomeIcon icon={faFileInvoice} className="me-2" onClick={() => completeMonthlyPayment(props)} title="Release Harvest" />

								)}
								{props.is_payment_released === "1" ? (

									"  Harvest Completed"

								) : (

									"  Release Harvest"

								)}

							</Dropdown.Item>
							{status == 1 && <Dropdown.Item className="text-primary" onClick={() => cancelsavingcommitment(props)} >
								<>
									<FontAwesomeIcon icon={faTimes} className="me-2" title="Cancel Saving Commitment" style={{ color: 'red' }} /> Cancel
								</>
							</Dropdown.Item>}
						</Dropdown.Menu>
					</Dropdown>
				</td>

			</tr>

		);

	};



	return (

		<>

			{loading && <Preloader show={true} />}

			<Card border="light" className="table-wrapper table-responsive shadow-sm">

				<Card.Body className="pt-0">

					<Table hover className="user-table align-items-center">

						<thead>

							<tr>

								<th className="border-bottom notsort" onClick={() => handleSort('id')}>

									Record Id {getSortIcon('id')}

								</th>

								<th className="border-bottom" onClick={() => handleSort('memberName')}>

									Member Name {getSortIcon('memberName')}

								</th>

								<th className="border-bottom" onClick={() => handleSort('savingsAmount')}>

									Savings Amount {getSortIcon('savingsAmount')}

								</th>

								<th className="border-bottom" onClick={() => handleSort('collectionMonth')}>

									Collection Month {getSortIcon('collectionMonth')}

								</th>

								<th className="border-bottom" onClick={() => handleSort('endDate')}>

									End Date {getSortIcon('endDate')}

								</th>

								<th className="border-bottom" onClick={() => handleSort('status')}>

									Status {getSortIcon('status')}

								</th>

								<th className="border-bottom">Action</th>

							</tr>

						</thead>

						<tbody>

							{modifiedData.map(t => <TableRow key={`data-${t.id}`} {...t} />)}

						</tbody>

					</Table>

					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">

						<Nav>

							<Pagination className="mb-2 mb-lg-0">

								<Pagination.Prev onClick={() => onPageChange(prevPage)}>

									Previous

								</Pagination.Prev>



								{items.map((item, index) => (

									<Pagination.Item active={item === currentPage} onClick={() => onPageChange(item)}>{item}</Pagination.Item>

								))}



								<Pagination.Next onClick={() => onPageChange(nextPage)}>

									Next

								</Pagination.Next>

							</Pagination>

						</Nav>

						<small className="fw-bold">

							Showing <b>{totalUsers}</b> out of <b>{totalRecord}</b> entries

						</small>

					</Card.Footer>

				</Card.Body>

			</Card>





			{/* View For Saving Commitments */}

			<React.Fragment>

				<Modal as={Modal.Dialog} centered show={showCommitmentDefault} onHide={handleCloseCommitment}>

					<Modal.Header>

						<Modal.Title className="h6">Saving Commitment</Modal.Title>

						<Button variant="close" aria-label="Close" onClick={handleCloseCommitment} />

					</Modal.Header>



					<Modal.Body>

						<Row>

							<Col md={12}>

								<Col md={8} style={{ float: 'right' }}>

									<p>SC-00{commitmentData.id ?? ""}</p>

								</Col>

								<Col md={4}>

									<b>Record ID :</b>

								</Col>

							</Col>

							<Col md={12}>

								<Col md={8} style={{ float: 'right' }}>

									<p>{commitmentData.savers_name ?? ""}</p>

								</Col>

								<Col md={4}>

									<b>Member Name :</b>

								</Col>

							</Col>

							<Col md={12}>

								<Col md={8} style={{ float: 'right' }}>

									<p>{commitmentData.collection_month ?? ""}</p>

								</Col>

								<Col md={4}>

									<b>Collection Month :</b>

								</Col>

							</Col>

							<Col md={12}>

								<Col md={8} style={{ float: 'right' }}>

									<p>{commitmentData.amount ?? ""}</p>

								</Col>

								<Col md={4}>

									<b>Monthly Savings Amount :</b>

								</Col>

							</Col>

							<Col md={12}>

								<Col md={8} style={{ float: 'right' }}>

									<p>{commitmentData.saving_goal ?? ""}</p>

								</Col>

								<Col md={4}>

									<b>Savings Goal :</b>

								</Col>

							</Col>

						</Row>

					</Modal.Body>



				</Modal>

			</React.Fragment>

			{/*End View For Saving Commitments */}






			{/*Edit */}

			<React.Fragment>

				<Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>

					<Modal.Header>

						<Modal.Title className="h6">Edit Saving Commitment</Modal.Title>

						<Button variant="close" aria-label="Close" onClick={handleClose} />

					</Modal.Header>

					<Modal.Body>

						<Row>

							<Col xs={12} xl={12}>

								<Card border="light" className="bg-white shadow-sm mb-4">

									<Card.Body>

										<h5 className="mb-4">Edit Saving Commitment</h5>

										<Form onSubmit={handleSubmit}>

											<Row>

												<Col md={12} className="mb-3">

													<Form.Group id="amount">

														<Form.Label>Edit Savings Commitment Amount</Form.Label>

														<Form.Select defaultValue={formData.amount} name="amount" onChange={handleInputChange}>

															<option value="">Choose Commitment Amount</option>

															{amountDatas.map((amountData, index) => (

																<option key={amountData.amount} value={amountData.amount}>

																	£{amountData.amount}

																</option>

															))}

														</Form.Select>

													</Form.Group>

													<div className="error-message">{errors.amount}</div>

												</Col>

												<Col md={12} className="mb-3">

													<Form.Group id="saving_goal">

														<Form.Label>Savings Goal</Form.Label>

														<Form.Select defaultValue={formData.saving_goal} name="saving_goal" onChange={handleInputChange}>

															<option value="">Choose Saving goal</option>

															{commitmentgoal.map((goal, index) => (

																<option key={goal.id} value={goal.id}>

																	{goal.name}

																</option>

															))}

														</Form.Select>

													</Form.Group>

													<div className="error-message">{errors.saving_goal}</div>

												</Col>

												{formData.saving_goal === "9" && (

													<Col md={12} className="mb-3">

														<Form.Group id="commitment_details">

															<Form.Control type="text" name="commitment_details" placeholder="Enter Saving Commitment goal" onChange={handleInputChange} />

														</Form.Group>

														<div className="error-message">{errors.commitment_details}</div>

													</Col>

												)}

												<Col md={12} className="mb-3">

													<Form.Group id="collection_month">

														<Form.Label>Collection Month</Form.Label>

														<Form.Select defaultValue={formData.collection_month} name="collection_month" onChange={handleInputChange}>

															<option value="">Choose Collection Month</option>

															{commitmentmonth.map((month, index) => (

																<option key={month.month_name} value={month.month_name}>

																	{month.month_name}

																</option>

															))}

														</Form.Select>



													</Form.Group>

													<div className="error-message">{errors.collection_month}</div>

												</Col>

												<Col md={12} className="mb-3">

													<Form.Group id="collection_year">

														<Form.Label>Collection Year</Form.Label>

														<Form.Select defaultValue={formData.collection_year} name="collection_year" onChange={handleInputChange}>

															<option value="">Choose Collection Year</option>

															{yearsList.map((year, index) => (

																<option key={year} value={year}>

																	{year}

																</option>

															))}

														</Form.Select>

													</Form.Group>

													<div className="error-message">{errors.collection_year}</div>

												</Col>

											</Row>

											<div className="mt-3 center">

												<Button variant="primary" type="submit">Save</Button>

											</div>

										</Form>

									</Card.Body>

								</Card>

							</Col>

						</Row>

					</Modal.Body>

				</Modal>

			</React.Fragment>







		</>

	);

};