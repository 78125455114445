import React, { useEffect } from 'react';

const LogoutTimer = ({ timeout }) => {
  useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer); // Clear the previous timer
      timer = setTimeout(logoutUser, timeout); // Set a new timer
    };

    const logoutUser = () => {
      localStorage.removeItem('user');
	  localStorage.removeItem('lastActivityTime');
      window.location.href = '/';
    };

    const handleUserActivity = () => {
      resetTimer(); // Reset the timer on user activity
      localStorage.setItem('lastActivityTime', Date.now()); // Update last activity time in local storage
    };

    const checkLogoutCondition = () => {
      const lastActivityTime = localStorage.getItem('lastActivityTime');
      if (lastActivityTime && Date.now() - lastActivityTime > timeout) {
        logoutUser(); // Logout if time difference exceeds timeout
      } else {
        resetTimer(); // Reset the timer if not exceeding timeout
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        clearTimeout(timer); // Pause the timer when the page becomes hidden
      } else {
        checkLogoutCondition(); // Check logout condition when the page becomes visible again
      }
    };

    // Attach event listeners to reset the timer on user activity events
    ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress','change'].forEach(event => {
      window.addEventListener(event, handleUserActivity);
    });

    // Attach event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Initial setup
    checkLogoutCondition(); // Check logout condition on component mount

    // Cleanup event listeners
    return () => {
      // Remove all event listeners
      ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress','change'].forEach(event => {
        window.removeEventListener(event, handleUserActivity);
      });
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(timer); // Clear the timer on component unmount
    };
  }, [timeout]); // Re-run effect only if the timeout changes

  return null;
};

export default LogoutTimer;
