import React, { useState } from "react";
const Faqs = () => {
	
	const headingStyle = {
	  marginTop: '0in',
	  marginRight: '0in',
	  marginBottom: '8.0pt',
	  marginLeft: '0in',
	  fontSize: '21px',
	  fontFamily: '"var(--bs-font-sans-serif)"',
	  color: 'black',
	  textAlign:'center'
	};

	const questionStyle = {
	  marginTop: '0in',
	  marginRight: '0in',
	  marginBottom: '8.0pt',
	  marginLeft: '0in',
	  fontSize: '19px',
	  fontFamily: '"var(--bs-font-sans-serif)"',
	  color: 'black',
	};

	const answerStyle = {
	  marginTop: '0in',
	  marginRight: '0in',
	  marginBottom: '8.0pt',
	  marginLeft: '0.2in',
	  fontSize: '16px',
	  lineHeight: '107%',
	  color: 'black',
	};
	
	const staticPages = {
	  padding: '100px',
	  paddingTop: '100px',
	};
	
    return (
        <>
			<div class="staticPages" style={staticPages}>
				<p style={headingStyle}>
				  <strong>Platform FAQs</strong>
				</p>
				<p style={questionStyle}>
				  <strong>1. What is the purpose of the platform?</strong>
				</p>
				<p style={answerStyle}>
				  Our platform facilitates peer-to-peer savings, allowing members to contribute and receive savings from others in a secure and collaborative environment.
				</p>
                <p style={questionStyle}>
				  <strong>2. How does the monthly payment cycle work?</strong>
				</p>
				<p style={answerStyle}>
				 Members who pay the same monthly contribution amount are added to a cycle that usually runs for 10 – 12 months. Members are required to make monthly contributions as part of the savings pool. The amount is decided by the member and is paid on the 28th day of each month.
				</p>
                 <p style={questionStyle}>
				  <strong>3. How can I join the Platform?</strong>
				</p>
				<p style={answerStyle}>
				 The network is currently open to UK residents only. As the network is run based on trust between us and our members, joining the platform is strictly by invitation at the present time.
				</p>
                <p style={questionStyle}>
				  <strong>4. Can I invite others to join the platform?</strong>
				</p>
				<p style={answerStyle}>
				 Yes, as a member of the platform, you can invite trusted friends, family, or colleagues to join the platform. We encourage members to invite others to enhance the growth of the savings community.
				</p>
                 <p style={questionStyle}>
				  <strong>5. How is the annual membership fee charged?</strong>
				</p>
				<p style={answerStyle}>
				 An annual membership fee is charged to cover the operational costs of the platform. This fee is typically billed at the beginning of each membership year.
				</p>
                
                 <p style={questionStyle}>
				  <strong>6. Are there any administrative fees for specific transactions?</strong>
				</p>
				<p style={answerStyle}>
				 Yes, there may be administrative fees associated with certain services or transactions. These fees contribute to the administrative overhead involved in processing various activities within and outside the platform. Please see our Fee Schedule in the Members area for more information.
				</p>
                 <p style={questionStyle}>
				  <strong>7. How can I view the breakdown of fees?</strong>
				</p>
				<p style={answerStyle}>
				You can find a detailed breakdown of fees, including the annual membership fee and administrative charges, in our fee schedule. This information is accessible in the Members area of the platform.
				</p>
                <p style={questionStyle}>
				  <strong>8. Can I change my monthly contribution amount?</strong>
				</p>
				<p style={answerStyle}>
				You can adjust your monthly contribution amount before the annual cycle kicks off, provided there is a cohort paying the same amount that we can add you to. However, it is not possible to adjust your monthly contribution amount once the annual cycle has started.
				</p>
                 <p style={questionStyle}>
				  <strong>9. How much money can I save monthly?</strong>
				</p>
				<p style={answerStyle}>
				We do not dictate how much members can save monthly as we believe this should be based on what members can afford. However, members are currently able to save in increments of £250, up to a maximum of £1,000 for collection slot, and a maximum monthly savings of £4,000 or four slots of £1,000.
				</p>
                 <p style={questionStyle}>
				  <strong>10. How do I make monthly payments?</strong>
				</p>
				<p style={answerStyle}>
				Monthly payments can currently only be made to our business bank account supplied to you by your Savvey Savers Network Relationship Manager or Contact. Please note that we would never call or email our members to make payments in any other way. When we start to offer other payment methods, we will communicate these to Members via the proper channels.
				</p>
                 <p style={questionStyle}>
				  <strong>11. How will I receive my Savings Harvest payment?</strong>
				</p>
				<p style={answerStyle}>
				Your Savings Harvest payment is released to the bank account you supply to us on your pre-agreed collection month. Please note that the name on your bank account must match with your registration information for us to release your harvest to the bank account. To comply with Money Laundering regulations, we are not able to accommodate any requests to release your harvest into another person’s bank account.
				</p>
                <p style={questionStyle}>
				  <strong>12. Is the platform secure?</strong>
				</p>
				<p style={answerStyle}>
				Yes, we prioritize the security of our members' information and transactions. We employ industry-standard security measures to protect your data and ensure a safe savings experience. To protect your financial identity, we currently do not store your banking information on the platform.
				</p>
				<p style={questionStyle}>
				  <strong>13. How can I get support if I have questions about fees or transactions?</strong>
				</p>
				<p style={answerStyle}>
				  Our Customer Support Team is available to help you. Feel free to reach out through the platform's support channels or contact us via email on <a href="mailto:Support@SavveySaver.com">Support@SavveySaver.com</a>.
				</p>
			</div>

        </>
    )
}
export default Faqs;