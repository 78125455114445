import React, { useState } from "react";
const Fee = () => {
	
	const containerStyle = {
		marginTop: '0in',
		marginRight: '0in',
		marginBottom: '8.0pt',
		marginLeft: '0in',
		fontSize: '11.0pt',
		fontFamily: '"var(--bs-font-sans-serif)"',
		padding: '100px',
	    paddingTop: '100px',
	  };

	  const headingStyle = {
		fontSize: '24px',
		fontFamily: '"var(--bs-font-sans-serif)"',
		color: '#4472C4',
		color: 'black',
	    textAlign:'center'
	  };

	  const subHeadingStyle = {
		fontSize: '19px',
		fontFamily: '"var(--bs-font-sans-serif)"',
		color: 'black',
		marginTop: '0in',
	    marginRight: '0in',
	    marginBottom: '8.0pt',
	    marginLeft: '0in',
	  };

	  const contentStyle = {
		fontFamily: '"var(--bs-font-sans-serif)"',
		marginTop: '0in',
	    marginRight: '0in',
	    marginBottom: '8.0pt',
	    marginLeft: '0.2in',
	    fontSize: '16px',
	    lineHeight: '107%',
	    color: 'black',
	  };
	  
	
    return (
        <>
            <div class="staticPages" style={containerStyle}>
			  <p style={headingStyle}><strong>Network Membership and Administrative Fee Schedule</strong></p>
			  <p style={subHeadingStyle}><strong>Membership Fees</strong></p>
			  <p style={contentStyle}>
				To participate in our peer-to-peer savings platform, members are required to pay an annual membership fee of £35.99, as specified during the registration process. This fee contributes to the operational costs associated with maintaining the web platform and coordinating administrative tasks within and outside the platform.&nbsp;
			  </p>
			  <p style={contentStyle}>Members can currently save in increments of £250, and up to a maximum of £1,000 per slot for savings harvest and a maximum of £4,000 per year. The membership fee covers the maximum monthly contribution of £1,000 per slot, savings more than £1,000 per month will attract additional administrative fees as detailed in the next section.</p>
			  
			  <p style={subHeadingStyle}><strong>Administrative Fees</strong></p>
			  <p style={contentStyle}>
				In addition to the membership fee, there are various administrative fees associated with specific services or transactions. These fees cover the administrative overhead involved in processing and facilitating various activities within the platform.
			  </p>
			   <p style={contentStyle}>One of such fees is an additional £10 payable for every extra £1 to £999 saved in addition to the first £1,000 covered by the £35.99 annual membership fee. </p>
			    <p style={contentStyle}>For example, if you are saving a total of £1,000 monthly, this will be covered by your flat rate annual membership fee of £35.99. However, if you decide to save an additional £200 to make your monthly savings £1,200, a one-off administrative fee of £10 is chargeable in addition to your annual membership fee, and if you decide to save £2500 monthly, then the fee is £20 plus your annual membership fee.</p>
			  
			  <p style={subHeadingStyle}><strong>Late Fees</strong></p>
			  <p style={contentStyle}>
				As a Platform, our aim is to support our members to achieve their financial goals quicker, and we do this by ensuring they can access their savings harvest on time. Monthly contribution payments are due on the 28<sup>th</sup> day of each month, and Members who pay their monthly contribution later than this date may incur an added £50 late fee charge.
			  </p>
			  
			  <p style={subHeadingStyle}><strong>Penalties</strong></p>
			  <p style={contentStyle}>
				Savvey Savers Network Limited reserves the right to terminate a defaulting Member’s membership. Where a membership termination is due to the Member defaulting on payments, any contributions already made by the member will be released to them by the end of the cycle, with 20% penalty charged on their total contribution if they have not already harvested their savings. 
			  </p>
			  <p style={contentStyle}>Where the Member defaults after harvesting their savings and before the end of their cycle, and their collection month is not December, the Platform reserves the right retrieve the monies owed by the Member, including to taking legal action against the Member where it deems appropriate; and the member will be liable to pay all legal expenses incurred by Savvey Savers Limited.</p>
			  
			   <p style={subHeadingStyle}><strong>Important Notice</strong></p>
			   <p style={contentStyle}>It is essential for members to review this information regularly, as fees may be subject to change, and any updates will be communicated in advance.
We are committed to maintaining transparency in our fee structure, and we encourage members to reach out to their Relationship Manager / Contact for any clarifications or further assistance about fees and charges.
</p>
			  
			</div>

        </>
    )
}
export default Fee;