import React, { useState } from "react";
const Membership = () => {
	
	const containerStyle = {
		fontFamily: '"var(--bs-font-sans-serif)"',
	};

	const headingStyle = {
		fontSize: '24px',
		lineHeight: '107%',
		fontFamily: '"var(--bs-font-sans-serif)"',
		textAlign:'center',
		color: 'black',
	};
	
	const headingStyle1 = {
		fontSize: '19px',
		lineHeight: '107%',
		fontFamily: '"var(--bs-font-sans-serif)"',
		color: 'black',
	};

	const subHeadingStyle = {
		color: 'black !important',
		fontFamily: '"var(--bs-font-sans-serif)"',
		marginTop: '0in',
	    marginRight: '0in',
	    marginBottom: '8.0pt',
	    marginLeft: '0.2in',
	    fontSize: '16px',
	    lineHeight: '107%',
	    color: 'black',
	};
	
	const paragraphStyle = {
		color: 'black !important',
		fontFamily: '"var(--bs-font-sans-serif)"',
		marginTop: '0in',
	    marginRight: '0in',
	    marginBottom: '8.0pt',
	    marginLeft: '0.2in',
	    fontSize: '16px',
	    lineHeight: '107%',
	    color: 'black',
	}
	
	const paragraphStyle1 = {
		color: 'black !important',
		fontFamily: '"var(--bs-font-sans-serif)"',
		marginTop: '0in',
	    marginRight: '0in',
	    marginBottom: '8.0pt',
	    marginLeft: '0.0in',
	    fontSize: '16px',
	    lineHeight: '107%',
	    color: 'black',
	}
	
	const staticPages = {
	  padding: '100px',
	  paddingTop: '100px',
	};
	
    return (
        <>
			<div class="staticPages" style={staticPages}>
				<div style={containerStyle}>
				  <p style={headingStyle}><strong>Savvey Savers Network Limited Membership Agreement</strong></p>
				  <p style={paragraphStyle1}>By becoming a network member of Savvey Savers Network Limited, you agree to the terms stated in this membership agreement.</p>
				  <p style={paragraphStyle1}>This Membership Agreement is entered into by and between Savvey Savers Network Limited, hereinafter referred to as the "Platform," any registered platform member that has paid a membership fee, hereinafter referred to as the "Member" or "Network Member."</p>
				  
				  <p style={headingStyle1}><strong>Definition of terms</strong></p>
				  
				  <p style={subHeadingStyle}><strong>Platform</strong>&nbsp;&ndash; the Savvey Savers Network Limited website where network members can register for and access their accounts.</p>
				  <p style={subHeadingStyle}><strong>Cycle / Annual Membership Period&nbsp;</strong>&ndash; a 12 calendar month period during which network members are required to make monthly payments to the tune of their pre-agreed monthly savings commitment amount.</p>
				  <p style={subHeadingStyle}><strong>Member / Network Member</strong>&ndash; an individual who has successfully completed registration on the platform, finalized their monthly Savings Commitment(s), and made their annual membership payment.</p>
				   <p style={subHeadingStyle}><strong>Membership Fees</strong>&ndash; an annual fee of £35.99 payable by an individual that wishes to become a Member of the Savvey Savers Network. Please see our Fees page for more information.</p>
				    <p style={subHeadingStyle}><strong>Administration / Admin Fees</strong>&ndash; additional administration fees payable by members wishing to save more than the defined threshold covered by the annual membership fee. Please see our Fees page for more information.</p>
					
					
					<p style={subHeadingStyle}><strong>Savings Harvest</strong>&ndash; the total amount of a Network Member’s monthly savings that is release to them at a pre-agreed time.</p>
					<p style={subHeadingStyle}><strong>Savings Commitment</strong>&ndash; the monthly amount that a Network Member commits to save monthly for a minimum period of 12 calendar months.</p>
					<p style={subHeadingStyle}><strong>Collection Month</strong>&ndash; the pre-agreed month that a Network Member’s savings harvest will be released to them.</p>
					<p style={subHeadingStyle}><strong>Platform Administrator / Admin</strong>&ndash; employees of Savvey Savers Network Limited responsible for moderating and supervising platform activities</p>
					<p style={subHeadingStyle}><strong>Relationship Manager</strong>&ndash; – a NetworkMember’s dedicated contact at Savvey Savers Network Limited.</p>
				  

				  <p style={headingStyle1}><strong>1. MEMBERSHIP</strong></p>
				  <p style={paragraphStyle}>1.1. Eligibility: You must be 18 years or older to become a Network Member of Savvey Savers Network Limited; and by signing this Agreement, the Member confirms that they meet the eligibility criteria for membership as specified by the Platform.</p>
				  <p style={paragraphStyle}>1.2. Term: This membership is effective as of the date of acceptance and payment of the annual membership fee; and shall continue for a minimum period of 12 calendar months unless terminated by either party in accordance with the terms herein.</p>
				  {/* Add more paragraphs with styles */}
				  <p style={headingStyle1}><strong>2. PLATFORM SERVICES AND OBLIGATIONS</strong></p>
				  <p style={paragraphStyle}>2.1. Peer-to-Peer Savings: The Platform is an &ldquo;invite-only&rdquo; platform that provides a peer-to-peer savings service that connects likeminded Members who wish to contribute a pre-agreed savings amount monthly; and disburse the total saved amount <strong>interest-free</strong> as a harvest to any Member(s) due a collection that month.</p>
				  <p style={paragraphStyle}>2.2. Access: Members will have access to the Platform's website or application and associated services during the term of their membership.</p>
				  <p style={paragraphStyle}>2.3. Platform Moderation: The Platform Admin will ensure that the services are always available to members and that any service outage is reported to members in a timely manner.</p>
				  {/* Add more paragraphs with styles */}
				  <p style={headingStyle1}><strong>3. MEMBERSHIP FEES</strong></p>
				  <p style={paragraphStyle}>3.1. Annual Membership Fee: The Member agrees to pay a membership fee as determined by the Platform. The membership fee is payable in advance and is non-refundable.</p>
				  <p style={paragraphStyle}>3.2. Payment Method: The Member agrees to pay the annual membership fee into the bank account details supplied to them by their Relationship Manager.</p>
				  {/* Add more paragraphs with styles */}
				  <p style={headingStyle1}><strong>4. SAVINGS CONTRIBUTIONS</strong></p>
				  <p style={paragraphStyle}>4.1. Payment Due Date: Monthly contribution payments are due on the 28<sup>th</sup> day of each calendar month.</p>
				  <p style={paragraphStyle}>4.2. Contribution Schedule: Members agree to contribute their pre-agreed Savings Commitment amount to the peer-to-peer savings pool on a monthly basis until the end of the Cycle, as determined by the Platform. Members agree to continue to make their monthly contributions even after harvesting their savings unless their Collection Month is December.</p>
				  <p style={paragraphStyle}>4.3. Withdrawal: Members may only withdraw their savings on their Collection Month and according to any other terms and conditions set by Savvey Savers Limited.</p>
				  <p style={paragraphStyle}>4.4. Savings Harvest Release: The Platform will ensure that Members&rsquo; harvest payments are released no later than the 2<sup>nd</sup> working day of the month preceding their collection month.</p>
				  <p style={paragraphStyle}>4.5. Withholding Savings Harvests: The Platform reserves the right to withhold or delay all or part of a Member&rsquo;s harvest payment if it suspects that the Member has or may violate the terms of this agreement.</p>
				  {/* Add more paragraphs with styles */}
				  <p style={headingStyle1}><strong>5. TERMINATION</strong></p>
				  <p style={paragraphStyle}>5.1. Termination by Member: A Member may terminate their membership before 14 days before the commence of or at the end of their annual membership period by providing written notice to their Relationship Manager. Any membership fees already paid are non-refundable.</p>
				  <p style={paragraphStyle}>5.2. Termination by Platform: The Platform reserves the right to terminate a Member's membership for violation of these terms or any other reason deemed appropriate by the Platform.</p>
				  {/* Add more paragraphs with styles */}
				  <p style={headingStyle1}><strong>6. PENALTIES</strong></p>
				  <p style={paragraphStyle}>6.1. Late Monthly Contribution: The Platform reserves the right to charge a &pound;50 flat late fee for any payments delayed beyond the 28<sup>th</sup> day of a calendar month.</p>
				  <p style={paragraphStyle}>6.2. Non-Payment of Monthly Contributions: The Platform reserves the right to terminate a defaulting Member&rsquo;s membership. Where a membership termination is as a result of the Member defaulting on payments, any contributions already made by the member will be released to them by the end of the cycle, with 20% penalty charged on their total contribution if they haven&rsquo;t already harvested their savings.</p>
				  <p style={paragraphStyle}>6.3. Legal Action: Where the Member defaults after harvesting their savings and before the end of their cycle, and their collection month is not December, the Platform reserves the right retrieve the monies owed by the Member, including to taking legal action against the Member where it deems appropriate; and the member will be liable to pay all legal expenses incurred by the Platform.</p>
				  {/* Add more paragraphs with styles */}
				  <p style={headingStyle1}><strong>7. CONFIDENTIALITY</strong></p>
				  <p style={paragraphStyle}>7.1. Member Information: The Platform agrees to keep Members&rsquo; information confidential and will not disclose it to third parties except as required by law.</p>
				  {/* Add more paragraphs with styles */}
				  <p style={headingStyle1}><strong>8. GOVERNING LAW</strong></p>
				  <p style={paragraphStyle}>8.1. Jurisdiction: This Agreement is governed by and construed in accordance with English laws. Any disputes arising under or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts in England.</p>
				  {/* Add more paragraphs with styles */}
				  <p style={headingStyle1}><strong>9. MISCELLANEOUS</strong></p>
				  <p style={paragraphStyle}>9.1. Amendments: The Platform reserves the right to amend these terms at any time. Members will be notified of any changes.</p>
				  <p style={paragraphStyle}>9.2. Entire Agreement: This Agreement constitutes the entire understanding between the parties and supersedes all prior agreements, whether written or oral.</p>
				</div>
				
			</div>
        </>
    )
}
export default Membership;