import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, Dropdown, InputGroup, Nav, Tab, Container, Modal } from '@themesberg/react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import Documentation from "../components/Documentation";

import Preloader from "../components/Preloader";
import Loader from "../components/Loader";

import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import axios from 'axios';
import swal from 'sweetalert';


export default () => {
	const [birthday, setBirthday] = useState("");	
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [notificationSwitch, setNotificationSwitch] = useState(false);
	const [emailSwitch, setEmailSwitch] = useState(false);
	const history = useHistory();
	
	const [savedcommitmentData, setsavedCommitmentData] = useState([]);
	const [savingCommitment, setSavingCommitment] = useState([]);
	
	const [monthID, setMonthID] = useState("");	
	
	// Create Users
	const [formData, setFormData] = useState([]);
  
	const [invalid, setInvalid] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [month, setMonth] = useState([]);
	const [amount, setAmount] = useState([]);
	
	const [showDefault, setShowDefault] = useState(false);
	const handleClose = () => setShowDefault(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		
		var checkedValue = e.target.checked;
		
		if(checkedValue === false){
			setFormData(formData.filter(item => item !== name));
		} else {
			setFormData([...formData, name]);
		}
	};
	
	const handleInputChangeAmount = (index, event) => {
	  const { name, checked } = event.target;
	  const updatedAmounts = [...amount]; // Create a copy of the amounts array
	  updatedAmounts[index].is_show = checked ? "1" : "0"; // Update the is_show property based on checkbox status
	  setAmount(updatedAmounts); // Update the state with the modified amounts array
	  console.log("amount",amount);
	};
	
	const storedUser = localStorage.getItem('user');
	if (!storedUser) {
	  // Redirect to the login URL
	  history.push('/'); // Replace '/login' with the actual URL of your login page
	  return false;
	}
	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;
	const user_id = userObject.user_id;
	
	useEffect(()=>{
	  const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/commitment_months';  
			
		axios.get(apiBaseURL)
		.then(response => {
			if(response.data.status==="success"){
			  setMonth(response.data.data);
			  
			  const filteredMonths = response.data.data.filter(month => month.is_show === '1');
			  const monthNames = filteredMonths.map(month => month.month_name);
			  setFormData(monthNames);
			  
			  
			  setNotificationSwitch(response.data.sms);
			  setEmailSwitch(response.data.email);
			  
			  
			  const filteredCommitments = response.data.saving_commitment_list.filter(commitments => commitments.is_show === '0');
			  const filterCommit = filteredCommitments.map(commit => commit.name);
			  setsavedCommitmentData(filterCommit);
			  
			  setSavingCommitment(response.data.saving_commitment_list);
			  
			  setAmount(response.data.commitment_amount);
			  
			  
			}
			setLoading(false)
		})
		.catch(error => {
		  console.error('Error:', error);
		});
	},[]);
	
	
	// Update Url Info
	const handleSubmit = (e) => {
		setLoading(true);
		e.preventDefault();
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/save_commitment_months'; 
		
		const formDatas = new FormData();
		formDatas.append('months', formData);
		formDatas.append('amount', monthID);
			
		axios.post(apiBaseURL, formDatas, {
		})
		.then(({ data }) => {
			if(data.status==="success"){
				swal("Success!", "Collection Month Updated Successfully.", "success");
			}
		}).catch(error => {
		});

		const apiBaseURL2 = process.env.REACT_APP_API_BASE_URL+'/save_notification_settings'; 
		
		const formDatas2 = new FormData();
		formDatas2.append('sms', notificationSwitch);
		formDatas2.append('email', emailSwitch);
			
		axios.post(apiBaseURL2, formDatas2, {
		})
		.then(({ data }) => {
			if(data.status==="success"){
				swal("Success!", "Notification Setting Updated Successfully.", "success");
			}
		}).catch(error => {
		});
		
		
		const apiBaseURL4 = process.env.REACT_APP_API_BASE_URL+'/save_commitment_amounts'; 
		const amountJSON = JSON.stringify(amount);
		const formDatas4 = new FormData();
		formDatas4.append('amount', amountJSON);
			
		axios.post(apiBaseURL4, formDatas4, {
		})
		.then(({ data }) => {
			if(data.status==="success"){
				swal("Success!", "Commmitment Amount Updated Successfully.", "success");
			}
		}).catch(error => {
		});


		const apiBaseURL3 = process.env.REACT_APP_API_BASE_URL+'/save_saving_commitments'; 
			
		const formDatas3 = new FormData();
		formDatas3.append('commitments', savedcommitmentData);	
			
		axios.post(apiBaseURL3, formDatas3, {
		})
		.then(({ data }) => {
			if(data.status==="success"){
				setLoading(false);
				swal("Success!", "Saving Commitments Updated Successfully.", "success");
			}
		}).catch(error => {
		});


	};
	
	
	
	
	// Notification Setting
	const handleCheckboxChange = (event) => {
		setNotificationSwitch(event.target.checked);
	};
	const handleCheckboxEmailChange = (event) => {
		setEmailSwitch(event.target.checked);
	};
	
	const handleNotificationSubmit = (e) => {
		e.preventDefault();
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/save_notification_settings'; 
		
		const formDatas = new FormData();
		formDatas.append('sms', notificationSwitch);
		formDatas.append('email', emailSwitch);
			
		axios.post(apiBaseURL, formDatas, {
		})
		.then(({ data }) => {
			if(data.status==="success"){
				swal("Success!", "Notification Setting Updated Successfully.", "success");
			}
		}).catch(error => {
		});
	};
	
	
	
	// commitments
	
	const handleInputChangeSaving = (e) => {
		const { name, value } = e.target;
		
		var checkedValue = e.target.checked;
		
		if(checkedValue === false){
			setsavedCommitmentData(savedcommitmentData.filter(item => item !== name));
		} else {
			setsavedCommitmentData([...savedcommitmentData, name]);
		}
	};
	
	const handleCommitmentSubmit = (e) => {
		e.preventDefault();
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/save_saving_commitments'; 
			
		const formDatas = new FormData();
		formDatas.append('commitments', savedcommitmentData);	
			
		axios.post(apiBaseURL, formDatas, {
		})
		.then(({ data }) => {
			if(data.status==="success"){
				swal("Success!", "Saving Commitments Updated Successfully.", "success");
			}
		}).catch(error => {
		});
	};
	
	
	
	// Add New commitment month
	const [commitmentformData, setCommitmentFormData] = useState({
		commitmentAmount: ''
	});
	const [errorsAmount, setErrorsAmount] = useState({
		commitmentAmount: ''
	});
	const handleSubmitCommitmentMonth = (e) => {
		
		e.preventDefault();
		// Validate email and password
		let valid = true;
		const newErrors = {
			commitmentAmount: ''
		};

		if (commitmentformData.commitmentAmount === "") {
			newErrors.commitmentAmount = 'Commitment Amount field is required';
			valid = false;
		} else if (isNaN(commitmentformData.commitmentAmount)) {
			newErrors.commitmentAmount = 'Commitment Amount must be a number';
			valid = false;
		}
		setErrorsAmount(newErrors);
		
		if(valid){
			setLoading(true);
			e.preventDefault();
			const apiBaseURLMonth = process.env.REACT_APP_API_BASE_URL+'/add_commitment_amount'; 
				
			axios.post(apiBaseURLMonth, commitmentformData, {
			})
			.then(({ data }) => {
				if(data.status==="success"){
					setLoading(false);
					swal("Success!", "Commitment Amount Added Successfully.", "success");
					setAmount(data.data);
					setShowDefault(false);
				}
			}).catch(error => {
			});
		}
	};
	const handleInputChangeCommitmentAmount = (e) => {
		const { name, value } = e.target;
		setCommitmentFormData({
		  ...commitmentformData,
		  [name]: value,
		});
	};
	
	
	
	
	const handleChangeAmount = (e) => {
		const { name, value } = e.target;
		setMonthID(value);
		
		e.preventDefault();
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/get_request_commitment_months_amount'; 
		
		const formDatas = new FormData();
		formDatas.append('amount', value);
			
		axios.post(apiBaseURL, formDatas, {
		})
		.then(({ data }) => {
			if(data.status==="success"){
				setMonth(data.data);
			  
			    const filteredMonths = data.data.filter(month => month.is_show === '1');
			    const monthNames = filteredMonths.map(month => month.month_name);
			    setFormData(monthNames);
			}
		}).catch(error => {
		});
		
	};
	
	
	
	if (loading) {
		//return <div>Loading...</div>;
	}
  
  return (
    <>
	{loading && <Preloader show={true} />}
	<Row>
	  <article>
		  <Container className="py-30 commitmentContainer">
		   
			<Tab.Container defaultActiveKey="home">
			  <Row>
				<Col lg={12}>
				  <Nav className="nav-tabs">
					<Nav.Item>
					  <Nav.Link eventKey="home" className="mb-sm-3 mb-md-0">
						Collection Month
					  </Nav.Link>
					</Nav.Item>
					<Nav.Item>
					  <Nav.Link eventKey="profile" className="mb-sm-3 mb-md-0">
						Notification Settings
					  </Nav.Link>
					</Nav.Item>
					<Nav.Item>
					  <Nav.Link eventKey="messages" className="mb-sm-3 mb-md-0">
						Saving Commitment
					  </Nav.Link>
					</Nav.Item>
					<Nav.Item>
					  <Nav.Link eventKey="commitment_amount" className="mb-sm-3 mb-md-0">
						Commitment Amount
					  </Nav.Link>
					</Nav.Item>
				  </Nav>
				</Col>
				<Col lg={10}>
				
				  <Tab.Content>
					<Tab.Pane eventKey="home" className="py-4">
					<Row>
						<Form>
						<Col lg={6}>
							<Row>
								<Col md={12} className="mb-3">
								  <Form.Group id="select_month">
									<Form.Select defaultValue="" name="select_month" onChange={handleChangeAmount}>
									  <option value="">Choose Amount</option>
									  {amount.map((amounts, index) => (
										<option key={amounts.id} value={amounts.id}>
										  {amounts.amount}
										</option>
									  ))}
									</Form.Select>
								  </Form.Group>
								</Col>
							</Row>
						  <table>
							  <thead>
								<tr>
								  <th colspan="2">Collection Month</th> 
								</tr>
							  </thead>
							  <tbody class="monthTbody">
								
								{month.map((months, index) => (
								  <tr key={index}>
									<td>
									 {/*
									  <Form.Check type="checkbox">
										<input type="checkbox" name={months.month_name} checked={formData.includes(months.month_name)}  className="me-2" onChange={handleInputChange} />
									  </Form.Check> */}
										<label className="switch">
										  <input type="checkbox" name={months.month_name} checked={formData.includes(months.month_name)}  className="me-2" onChange={handleInputChange} />
										  <span className="slider round"></span>
										</label>
									</td>
									<td>{months.month_name}</td>
								  </tr>
								))}
								
							  </tbody>
							</table>
							</Col>
							<Col lg={6}>
							{/* <div className="mt-3">
								<Button variant="primary" type="button" onClick={handleSubmit}>Save</Button>
							</div> */}
							</Col>
						</Form>
					</Row>	
					</Tab.Pane>
					
					<Tab.Pane eventKey="profile" className="py-4">
					
						<Form>
							<table>
							  <thead>
								<tr>
								  <th colspan="2">Notification Type</th>
								</tr>
							  </thead>
							  <tbody class="monthTbody">
									{/*
									<tr>
										<td>
											<label className="switch">
												  <input type="checkbox" name="notification_switch" checked={notificationSwitch} onChange={handleCheckboxChange}/>
												  <span className="slider round"></span>
											</label>
										</td>
										<td>SMS</td>
									</tr> */}
									<tr>
										<td>
											<label className="switch">
											  <input type="checkbox" name="email_switch" checked={emailSwitch} onChange={handleCheckboxEmailChange}/>
											  <span className="slider round"></span>
											</label>
										</td>
										<td>Email</td>
									</tr>
							  </tbody>
							</table>
							{/* <div className="mt-3">
								<Button variant="primary" type="button" onClick={handleNotificationSubmit}>Save</Button>
							</div> */}
						</Form>
					  
					</Tab.Pane>
					
					
					<Tab.Pane eventKey="messages" className="py-4">
							<Form>
							  <table>
								  <thead>
									<tr>
									  <th colspan="2">Saving Commitment</th>
									</tr>
								  </thead>
								  <tbody class="monthTbody">
									
									{savingCommitment.map((savingCommitments, index) => (
									  <tr key={index}>
										<td>
											<label className="switch">
											  <input type="checkbox" name={savingCommitments.name} checked={savedcommitmentData.includes(savingCommitments.name)}  className="me-2" onChange={handleInputChangeSaving} />
											  <span className="slider round"></span>
											</label>
										</td>
										<td>{savingCommitments.name}</td>
									  </tr>
									))}
									
								  </tbody>
								</table>
								{/* <div className="mt-3">
									<Button variant="primary" type="button" onClick={handleCommitmentSubmit}>Save</Button>
								</div> */}
							</Form>
					</Tab.Pane>
					
					<Tab.Pane eventKey="commitment_amount" className="py-4">
							{/*
							<Button variant="primary " type="button" style={{ float: 'right', height: '35px', width: '75px', 'line-height': '0.5' }} onClick={() => setShowDefault(true)}>Add</Button>
							*/}
							<Form>
							  <table>
								  <thead>
									<tr>
									  <th colspan="2">Commitment Amount</th>
									  <div className="mt-3">
									  </div>
									</tr>
								  </thead>
								  <tbody class="monthTbody">
									
									{amount.map((amounts, index) => (
									  <tr key={index}>
										<td>
										  <label className="switch">
											<input
											  type="checkbox"
											  name="amt[]"
											  checked={amounts.is_show === "1"} // Check if is_show is "1"
											  className="me-2"
											  onChange={(event) => handleInputChangeAmount(index, event)} // Pass the event to the handler function
											/>
											<span className="slider round"></span>
										  </label>
										</td>
										<td>{amounts.amount}</td>
									  </tr>
									))}
									
								  </tbody>
								</table>
							</Form>
					</Tab.Pane>
					
				  </Tab.Content>
				</Col>
				<Col lg={2}>
					<div className="mr-5 mt-4">
						<div class="commitmentButtons">
							<Button variant="primary " type="button" style={{ float: 'right', height: '35px', width: '75px', 'line-height': '0.5' }} onClick={() => setShowDefault(true)}>Add</Button>
							<Button variant="primary" type="button" onClick={handleSubmit} style={{ float: 'right', height: '35px', width: '75px', 'line-height': '0.5' }}>Save</Button>
						</div>
					</div>
				</Col>
			  </Row>
			</Tab.Container>
			
			{/* commitment month */}
			<Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
				<Modal.Header>
				  <Modal.Title className="h6">Add commitment month</Modal.Title>
				  <Button variant="close" aria-label="Close" onClick={handleClose} />
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xs={12} xl={12}>
							<Card border="light" className="bg-white mb-4">
							  <Card.Body>
								{/* <h5 className="mb-4">Add Question</h5> */}
								<Form onSubmit={handleSubmitCommitmentMonth}>
								  <Row>
									<Col md={12} className="mb-3">
									  <Form.Group id="commitmentAmount">
										<Form.Label>Commitment Amount<span class="error-message">*</span></Form.Label>
										<Form.Control type="text" name="commitmentAmount" placeholder="Enter Commitment Amount" onChange={handleInputChangeCommitmentAmount}/>
									  </Form.Group>
									  <div className="error-message">{errorsAmount.commitmentAmount}</div>
									</Col>
								  </Row>
								  
								  <div className="mt-3 center">
									<Button variant="primary" type="submit">Save</Button>
								  </div>
								</Form>
							  </Card.Body>
							</Card>
						</Col>
					  </Row>
				</Modal.Body>
			  </Modal>
			  {/* end commitment month */}

		  </Container>
		</article>
	  </Row>
    </>
	
	
  );
};
