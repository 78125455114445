import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, Dropdown, ButtonGroup, Breadcrumb, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import { Link, useHistory } from 'react-router-dom';
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { MonthRequestTable } from "../components/MonthRequestTable";
import Preloader from "../components/Preloader";
import Loader from "../components/Loader";

export default () => {
	
	const [birthday, setBirthday] = useState("");	
	const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => setShowDefault(false);
	const [search, setSearch] = useState("");
	const [data, setData] = useState([]);
	const [requested_months, setRequestedMonth] = useState([]);
	const [remaining_months, setRemainingMonth] = useState([]);
	const [savingCommitment, setSavingCommitment] = useState([]);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState([]);
	
	const [isRequested, setIsRequested] = useState(false);
	
	const storedUser = localStorage.getItem('user');
	if (!storedUser) {
	  // Redirect to the login URL
	  history.push('/'); // Replace '/login' with the actual URL of your login page
	  return false;
	}
	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;
	const user_id = userObject.user_id;
	const role_id = userObject.role_id;
	
	// Create Users
	const [formData, setFormData] = useState({
		requested_month: '',
		reason: '',
		amount:'',
		saving_goal:'',
		commitment_details:'',
		buttonName: '',
		requested_month_for:'',
		isRequested:isRequested
	});

	const [errors, setErrors] = useState({
		requested_month: '',
		reason: '',
		amount:'',
		saving_goal:'',
		commitment_details:'',
		requested_month_for:'',
	});
  
	const [invalid, setInvalid] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
		  ...formData,
		  [name]: value,
		});
		
		if(name === "amount"){
			const apiBaseUrlForMonthThroughAmount = process.env.REACT_APP_API_BASE_URL+'/get_request_amount_available_month'; 
			const formDatas = new FormData();
			formDatas.append('amount', value);
				
			axios.post(apiBaseUrlForMonthThroughAmount, formDatas, {
			})
			.then(({ data }) => {
				if(data.status==="success"){
					setRequestedMonth(data.data)
					setRemainingMonth(data.remainingMonth)
					
					setFormData(prevData => ({
						...prevData,
						["requested_month"]: ''
					  }));
					
				}
			}).catch(error => {
			});
		}
	};
  
	const handleSubmit = (e) => {
		e.preventDefault();
		// Get the name of the button clicked
		const buttonName = e.nativeEvent.submitter.name;

		const formsbutton = formData['buttonName'] = buttonName;

		//console.log(formData);
		let valid = true;
		const newErrors = {
		  requested_month: '',
		  reason: '',
		  amount:'',
		  saving_goal:'',
		  commitment_details: '',
		};

		if(formData.isRequested === false){
			if(formData.requested_month === ""){
				newErrors.requested_month = 'Please Select Month';
				valid = false;
			}
		} else {
			if(formData.requested_month_for === ""){
				newErrors.requested_month_for = 'Please Select Requested Month';
				valid = false;
			}
			if(formData.reason === ""){
				newErrors.reason = 'Please Enter Requested Reason';
				valid = false;
			}
		}
		
		if(formData.amount === ""){
			newErrors.amount = 'Please Select Requested Amount';
			valid = false;
		}
		if(formData.saving_goal === ""){
			newErrors.saving_goal = 'Please Select Saving Goal';
			valid = false;
		}
		if(formData.saving_goal == "8" && formData.commitment_details === ""){
			newErrors.commitment_details = 'Commitment Details is required';
			valid = false;
		}
		setErrors(newErrors);

		if (valid) {
			setLoading(true);

			const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/request_collection_month_request'; 
			
			axios.post(apiBaseURL, formData, {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			})
			.then(({ data }) => {
				if(data.status==="success"){
					setShowDefault(false);
				}
				setLoading(false);
				window.location.reload();
			}).catch(error => {
				setLoading(false);
			});
			
		}
	};
	// End Create Users

	const handleInputChangeSearch = (e) => {
		const { name, value } = e.target;
		setSearch(value);
	};
	
	const sethandleRequested = (e) => {
		setIsRequested(true);
		
		setFormData(prevData => ({
					...prevData,
					["isRequested"]: true
				  }));
		
	};
	
	
	// commitment months
	useEffect(()=>{
	  const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/commitment_months';  
			
		axios.get(apiBaseURL)
		.then(response => {
			if(response.data.status==="success"){
			  //setRequestedMonth(response.data.data);
			  
			  const filteredCommitments = response.data.saving_commitment_list.filter(commitments => commitments.is_show === '0');
			  const filterCommit = filteredCommitments.map(commit => commit.name);
			  setSavingCommitment(filterCommit);
			  setAmount(response.data.commitment_amount)
			  
			  setSavingCommitment(response.data.saving_commitment_list);
			  
			}
			setLoading(false);
		})
		.catch(error => {
		  console.error('Error:', error);
		});
	},[]);
	
	
	if (loading) {
		//return <Preloader show={loading ? true : false} />
	}
		
	
  return (
    <>
	  {loading && <Preloader show={true} />}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Submitted Requests</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Submitted Requests</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" name="searchKeyword" onChange={handleInputChangeSearch}/>
            </InputGroup>
          </Col>
		  {role_id!= "1" &&
          <Col xs={4} md={2} xl={2} className="ps-md-0 text-end">
			  {/*
            <ButtonGroup>
				<Button variant="primary" className="m-1" size="sm" onClick={() => setShowDefault(true)}>Request Collection Month</Button>
			</ButtonGroup>
			  */}
          </Col>
		  }
        </Row>
      </div>

      <MonthRequestTable data={search}/>
	  
	  
	  <React.Fragment>
		  <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
			<Modal.Header>
			  <Modal.Title className="h6">Request Collection Month</Modal.Title>
			  <Button variant="close" aria-label="Close" onClick={handleClose} />
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xs={12} xl={12}>
						<Card border="light" className="bg-white shadow-sm mb-4">
						  <Card.Body>
							<h5 className="mb-4">Request Collection Month</h5>
							<Form onSubmit={handleSubmit}>
							  <Row>
								<Col md={12} className="mb-3">
									<Form.Group id="amount">
										<Form.Label>Savings Commitment Amount</Form.Label>
										<Form.Select defaultValue="" name="amount" onChange={handleInputChange}>
										  <option value="">Choose Commitment Amount</option>
										  {amount.map((amountData, index) => (
											<option key={amountData.amount} value={amountData.amount}>
											  £{amountData.amount}
											</option>
										  ))}
										</Form.Select>
									</Form.Group>
									 <div className="error-message">{errors.amount}</div>
								</Col>
								{!isRequested && 
								<Col md={12} className="mb-3">
								  <Form.Group id="requested_month">
									<Form.Label>Month</Form.Label>
									<Form.Select defaultValue="" name="requested_month" onChange={handleInputChange}>
									  <option value="">Choose Month</option>
									  {requested_months.map((month, index) => (
											<option key={month.month_name} value={month.id}>
												{month.month_name}
											</option>
										))}
									</Form.Select>
								  </Form.Group>
								  <div className="error-message">{errors.requested_month}</div>
								</Col>
								}
								
								{isRequested && 
									<Col md={12} className="mb-3">
									  <Form.Group id="requested_month_for">
										<Form.Label>Requested Month For</Form.Label>
										<Form.Select defaultValue="" name="requested_month_for" onChange={handleInputChange}>
										  <option value="">Choose Requested Month For</option>
											{remaining_months.map((month, index) => (
												<option key={month.month_name} value={month.id}>
													{month.month_name}
												</option>
											))}
										</Form.Select>
									  </Form.Group>
									  <div className="error-message">{errors.requested_month_for}</div>
									</Col>
								}
								
								<Col md={12} className="mb-3" style={{ textAlign: 'right' }}>
									<Button variant="primary" className="m-1" size="sm" onClick={() => sethandleRequested(true)}>Request Month</Button>
								</Col>
								
								<Col md={12} className="mb-3">
									<Form.Group id="saving_goal">
										<Form.Label>Savings Goal</Form.Label>
										<Form.Select defaultValue="" name="saving_goal" onChange={handleInputChange}>
										  <option value="">Choose Saving goal</option>
										  {savingCommitment.map((goal, index) => (
											<option key={goal.id} value={goal.id}>
											  {goal.name}
											</option>
										  ))}
										</Form.Select>
									</Form.Group>
									<div className="error-message">{errors.saving_goal}</div>
								</Col>
								{formData.saving_goal === "8" && (
									<Col md={12} className="mb-3">
									  <Form.Group id="commitment_details">
										<Form.Control type="text" name="commitment_details" placeholder="Enter Saving Commitment goal" onChange={handleInputChange} />
									  </Form.Group>
									  <div className="error-message">{errors.commitment_details}</div>
									</Col>
								)}
								{isRequested && 
								<Col md={12} className="mb-3">
								  <Form.Group id="Reason">
									<Form.Label>Reason</Form.Label>
									<Form.Control as="textarea" name="reason" placeholder="Enter Requested Reason" onChange={handleInputChange} rows="3" />
								  </Form.Group>
								  <div className="error-message">{errors.reason}</div>
								</Col>
								}
							  </Row>
							  <div className="mt-3 center">
								<Button variant="primary" name="save" data-button-name="save" type="submit">Save</Button>&nbsp;&nbsp;
								<Button variant="primary" onClick={handleClose}>Cancel</Button>&nbsp;
							  </div>
							</Form>
						  </Card.Body>
						</Card>
					</Col>
				  </Row>
			</Modal.Body>
		  </Modal>
		</React.Fragment>
	  
    </>
  );
};
